

import React, { Component,createRef } from 'react'
import {Container, Row, Col,Image,Button,Modal,Card  } from 'react-bootstrap';
import "./VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import * as htmlToImage from "html-to-image";
import loader from '../../images/loader.gif';
import FrontImg from '../../images/front_img.jpeg';
import BackImg from '../../images/back_img.png';
import QRCode from "react-qr-code";

class SuccessPage extends Component {
    constructor(props) {
        super(props)
        this.myref = createRef();
        this.state = {
            downloadloadingSymCard:"hide",
			blobStr:"",
			bloburl:""
        }
      
    }

    downloadBGCard = async () => {
        
        this.setState({downloadloadingSymCard:"show" });

        const node = this.myref.current;
        console.log(node)
        htmlToImage.toPng(node).then((dataUrl) => {
            this.saveAs(dataUrl, 'BGCard.png');
        })
    }


     saveAs = (blob, fileName) =>{
        
        console.log(blob);

		//var strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
        //window.open(blob, "_blank", strWindowFeatures);

        this.setState({downloadloadingSymCard:"hide"});

        var elem = document.createElement('a');
        elem.href = blob
        elem.download = fileName;
        elem.style = 'display:none;';
        (document.body || document.documentElement).appendChild(elem);
        if (typeof elem.click === 'function') {
            elem.click();
        } else {
            elem.target = '_blank';
            elem.dispatchEvent(new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true
            }));
        }
        URL.revokeObjectURL(elem.href);
        elem.remove()
      }

      
    
    
    render() {
        return (
            <Modal {...this.props} 
            size="lg"  aria-labelledby="example-modal-sizes-title-lg" centered className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>భవిష్యత్తుకు గ్యారెంటీ - ఇది బాబు గ్యారెంటీ</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <Row>
                            <Col sm={12}>
                                <h6 className='text_center mt-4 color_green fw-bold f-14'>భవిష్యత్తుకు గ్యారెంటీ కార్యక్రమానికి నమోదు అయినందుకు ధన్యవాదములు</h6>
                            <div className='htmlToImageVis' ref={this.myref}>

                                    <Row>
                                        <Col sm={6}>
                                            <Card className="text-white">
                                                <Card.Img src={FrontImg} alt="Card image"  />
                                                <div className='qr_code_pos1'>
                                                    <QRCode
                                                        size={80}
                                                        viewBox={`0 0 256 256`}
                                                        value={`www.tdpmanifesto.com/?uid=${this.props?.location?.params?.qrCodeUUID == undefined ? "" : this.props?.location?.params?.qrCodeUUID}`}
                                                    />
                                                </div>
                                                <Card.ImgOverlay className='main_text_pos'>
                                                    <Card.Title className='cardId_pos1 color_black fw-bold f-14'>{this.props.stateData.volunteerPhysicalCardId}</Card.Title>
                                                    <Card.Title className='cardName_pos1 color_black fw-bold f-14'>{this.props.stateData.volunteerName}</Card.Title>
                                                    <Card.Title className='cardPhone_pos1 color_black fw-bold f-14'>{this.props.stateData.volunteerMobile}</Card.Title>
                                                    <Card.Title className='cardCons_pos1 color_black fw-bold f-14'>{this.props.stateData.SelfconstituencyValue.label}</Card.Title>
                                                </Card.ImgOverlay>
                                            </Card>    
                                        </Col>
                                        <Col sm={6}>
                                            <Card className="text-white mt-2">
                                                <Card.Img src={BackImg} alt="Card image"  />
                                            </Card>
                                        </Col>
                                    </Row>
                                        
                                    </div>
                                <div className='text-center'>
                                    <Row>
                                    <   Col>
                                            <Button variant="success" className='mt-4 f-14 fw-bold' value={require('../../images/physical_card.jpeg')} onClick={() => this.downloadBGCard()}>Download Card
                                            {
                                                this.state.downloadloadingSymCard == "show" ?
                                                (
                                                    <Image src={loader} alt="loader" className='loader_cls ms-2'></Image>
                                                )
                                                :
                                                ("")
                                            }
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button variant="warning" className='mt-4 f-14 fw-bold color_white' onClick={() => this.props.backToEnrollPage()}>Start New Enroll</Button>
                                        </Col>
                                    </Row>

            
                                </div>
                                
                            </Col>
                        </Row>   
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default withRouter(SuccessPage);
  


  