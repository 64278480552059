
import React ,{ Component } from 'react';
import {Container, Row, Col,Button} from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./NavBar.css";
import { withRouter,Link } from "react-router-dom";
import TDPLOGO from '../images/tdp-logo.png';
import TDPLOGO1 from '../images/tdp-logo1.png';
import NTRLOGO from '../images/ntr-logo.png';

class NavBarLoginPageWithScanUrl extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
    }
  
    componentDidMount() {

    }

    render() {
        return (
            <section className='bg_image_navbar'>
                
                <Navbar expand="lg" className='border_bottom'>
                        <Container>
                            <Link to={{
                                pathname: "/",
                                
                                }}>
                                <Navbar.Brand ><img src={TDPLOGO} alt="TDPLOGO" className=''></img></Navbar.Brand>
                             </Link>
                            
                            <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                
                                <Nav.Link href="/home" className='active'>Home</Nav.Link>
                                {/*<Nav.Link href="#link">మన పార్టీ</Nav.Link>
                                <Nav.Link href="#home">సంక్షేమం</Nav.Link>
                                <Nav.Link href="#link">కార్యకర్తలు</Nav.Link>*/}
                            </Nav>
                            <Navbar.Brand  className='mobile_view_none'><img src={TDPLOGO1} alt="TDPLOGONEW" className=''></img></Navbar.Brand>
                            <Navbar.Brand  className='mobile_view_none'><img src={NTRLOGO} alt="NTRLOGO" className=''></img></Navbar.Brand>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
            </section>
        )
    }
  }
  
  export default withRouter(NavBarLoginPageWithScanUrl);
  