
import React ,{ Component } from 'react';
import {Container, Row, Col,Form,Card,Carousel} from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import "./Home.css";
import Footer from '../FooterSection/Footer';
import VoterSearchEnrollSave from '../Volunteer/VoterSearchEnrollSave';
import CuratorWidget from '../CuratorWidget';

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
    }
  
    componentDidMount() {
        
           
       
    }

   


    render() {
        
        return (
            <>
            <section className='bg_image_home border_bottom_green'>
                <Container>
                        <Row>
                            <Col sm={12}>
                                <div className='flex-align-hero-section bg_cbn_img'>
                                <div>
                                    <img src="https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/bharosa_img.png" alt="Bharosa_img" className='w_res1_100'></img>
                                </div>
                                    <div className='image_position'>
                                        <h2 className='color_yellow fw-bold text_center_align'><span className='f-45'>తెలుగుదేశం పార్టీ</span> <span className='f-35'>సిద్ధాంతం </span></h2>
                                        <h2 className='color_yellow fw-bold mt_2_5 f-35 f-italic text_center_align'>అభివృద్ధి తోనే మరింత సంక్షేమం</h2>
                                        <h2 className='color_yellow fw-bold mt_2_5 f-35 f-italic text_center_align'>దాని ద్వారా </h2>
                                        <h2 className='color_yellow fw-bold mt_2_5 f-45 f-italic text_center_align'> భవిష్యత్తుకు గ్యారెంటీ</h2>
                                    </div>
                                </div> 
                            </Col>
                        </Row>    
                </Container> 
                <h6 className='bg_yellow text-center fw-bold mb-0 f-14-res line-height-res-32 f-14'>ఇదే సిద్ధాంతం ఇదే సంకల్పంతో తెలుగుదేశం ఇస్తున్న హామీలు - ఈ హామీలు గురుంచి ఎప్పటికపుడు తాజా సమాచారం కోసం మీ వివరాల్ని నమోదు చేసుకొని మీ అభిప్రాయాల్ని తెలపండి.</h6>
                
            </section>
            <section className='bg_light_yellow'>
                <Container>
                    <Row>
                        <Col className='mobile_view_none'>
                            <ul className='list-inline hash_tag_css'>
                                <li>#TDPFutureGuaranty</li>
                                <li>#TDPMahaShakthi</li>
                                <li>#TDPYuvaGalam</li>
                                <li>#TDPAnnaDatha</li>
                                <li>#TDPIntintikiNeeru</li>
                                <li>#TDPBCRakshnaChattam</li>
                                <li>#TDPPoorToRich</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className='mobile_view_show'>
                        <Col>
                            <ul className='list-inline hash_tag_css'>
                                <li>#TDPFutureGuaranty,#TDPMahaShakthi, #TDPYuvaGalam,#TDPAnnaDatha, #TDPIntintikiNeeru,#TDPBCRakshnaChattam, #TDPPoorToRich</li>
                            </ul>
                        </Col>
                    </Row>
                    {/*<Row>
                        <Col>
                            <h1 className='text-center f-40 fw-bold mt-3'><span className='color_red'>నిరంతర సమాచారం కొరకు</span>  నమోదు కాగలరు </h1>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col sm={10}>
                            <Row>
                                <Col sm={5} className='mt-3 mb-5'>
                                    <div className='reg_form_css'>
                                        <VoterSearchEnrollSave/>
                                    </div>
                                </Col>
                                <Col sm={7} className='mt-3 mb-5'>
                                <Carousel controls={false}>
                                    <Carousel.Item interval={5000}>
                                        <Card className="text_border_css">
                                            <Card.Body >
                                                <Card.Text className='text_font'>
                                                    <h5 className='fw-bold'><img src="https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/MS_logo.png"  alt="Bharosa_img" className='width_height_small'></img></h5>
                                                    <h4>ప్రతి కుటుంబంలో 18 ఏళ్ళు నిండిన ప్రతి స్త్రీకి "స్త్రీనిధి" కింద నెలకు 1500 రూపాయలను నేరుగా వారి ఖాతాల్లో జమ చేస్తాము.</h4>
                                                    <h4>'తల్లికి వందనం' పథకం కింద మీ ఇంట్లో ఎంతమంది పిల్లలు చదువుతుంటే వారందరికీ ఒక్కొక్కరికీ ఏడాదికి రూ.15,000లు అందిస్తాము.</h4>
                                                    <h4>"దీపం" పథకం కింద ప్రతి కుటుంబానికి ఏడాదికి 3 గ్యాస్ సిలిండర్లను ఉచితంగా ఇస్తాము. </h4>
                                                    <h4>ఉచిత బస్సు ప్రయాణం పాతకము ద్వారా మహిళలందరికీ టికెట్ లేని ప్రయాణ సౌకర్యం కలిగిస్తాము.</h4>
                                                </Card.Text>
                                                <Card.Text className='text_font'>
                                                    <h5 className='fw-bold'><img src= "https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/AnnaData_logo.png" alt="AnnaData_logo" className='width_height_small'></img></h5>
                                                    <h4>ఈ అన్నదాత పథకం కింద ఆంధ్రప్రదేశ్ లోని ప్రతి రైతుకు ఏడాదికి 20,000 రూపాయల ఆర్థిక సాయం అందిస్తాము.</h4>
                                                </Card.Text>
                                                <Card.Text className='text_font'>
                                                    <h5 className='fw-bold'><img src= "https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/YuvaGalam_logo.png" alt="YuvaGalam_logo" className='width_height_small'></img></h5>
                                                    <h4>ఆంధ్రప్రదేశ్ లోని నిరుద్యోగులకు 20 లక్షల ఉద్యోగాలు ఇస్తాము.</h4>
                                                    <h4>ప్రతి నిరుద్యోగికి 'యువగళం నిధి' కింద నెలకు 2500 రూపాయలను ఇస్తాము.</h4>
                                                </Card.Text>
                                                <Card.Text className='text_font '>
                                                    <h5 className='fw-bold'><img src= "https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/HW_logo.png" alt="HW_logo" className='width_height_small'></img></h5>
                                                    <h4>చంద్రబాబుగారి ప్రభుత్వం అధికారంలోకి రాగానే  ఇంటింటికీ మంచి నీరు పథకం కింద ప్రతి ఇంటికీ కుళాయి కనెక్షన్ ఇస్తాము.</h4>
                                                </Card.Text>
                                                <Card.Text className='text_font '>
                                                    <h5 className='fw-bold'><img src="https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/PTR_logo.png" alt="PTR_logo" className='width_height_small'></img></h5>
                                                    <h4>పేదలను సంపన్నులను చేస్తుంది.</h4>
                                                    <h4>ఐదేళ్ళలో పేదల ఆదాయాన్ని రెట్టింపు చేస్తుంది.</h4>
                                                </Card.Text>
                                                <Card.Text className='text_font '>
                                                    <h5 className='fw-bold'><img src= "https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/BC_logo.png" alt="BC_logo" className='width_height_small'></img></h5>
                                                    <h4>బీసీలకు రక్షణ చట్టం తెచ్చి... వారికి అన్ని విధాలా అండగా నిలుస్తుంది తెలుగుదేశం పార్టీ..</h4>
                                                </Card.Text>
                                                
                                            </Card.Body>
                                        </Card>
                                    </Carousel.Item>

                                    {/*<Carousel.Item interval={5000}>
                                        <Card className="text_border_css">
                                            <Card.Body >
                                                <Card.Text className='text_font mt_6'>
                                                    <h5 className='fw-bold'><img src={HW_logo} alt="HW_logo" className='width_height_small'></img></h5>
                                                    <h4>చంద్రబాబుగారి ప్రభుత్వం అధికారంలోకి రాగానే  ఇంటింటికీ మంచి నీరు పథకం కింద ప్రతి ఇంటికీ కుళాయి కనెక్షన్ ఇస్తాము.</h4>
                                                </Card.Text>
                                                <Card.Text className='text_font mt_6'>
                                                    <h5 className='fw-bold'><img src={PTR_logo} alt="PTR_logo" className='width_height_small'></img></h5>
                                                    <h4>పేదలను సంపన్నులను చేస్తుంది.</h4>
                                                    <h4>ఐదేళ్ళలో పేదల ఆదాయాన్ని రెట్టింపు చేస్తుంది.</h4>
                                                </Card.Text>
                                                <Card.Text className='text_font mt_6'>
                                                    <h5 className='fw-bold'><img src={BC_logo} alt="BC_logo" className='width_height_small'></img></h5>
                                                    <h4>బీసీలకు రక్షణ చట్టం తెచ్చి... వారికి అన్ని విధాలా అండగా నిలుస్తుంది తెలుగుదేశం పార్టీ..</h4>
                                                </Card.Text>
                                                
                                            </Card.Body>
                                        </Card>
                                    </Carousel.Item>
                                    
                                    </Carousel>
                                    
                                </Col>
                                
                               
                            </Row>
                         </Col>
                    </Row>*/}
                    
                </Container>
            </section>
            <section className='bg_MS pad_5rem'>
                <Container>
                        <Row>
                            <Col sm={12}>
                                <div className='flex-blocks-manifest-list '>
                                <div><img src= "https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/MS_bg.png" alt="Bharosa_img"  className='w_res_100'></img></div>
                                    <div className=''>
                                        <img src="https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/MS_logo.png" alt="MS_LOGO" className='center-auto'></img>
                                        <ul className='list_type_css'>
                                            <li>ప్రతి కుటుంబంలో 18 ఏళ్ళు నిండిన ప్రతి స్త్రీకి "స్త్రీనిధి" కింద నెలకు 1500 రూపాయలను <br/>నేరుగా వారి ఖాతాల్లో జమ చేస్తాము.</li>
                                            <li>'తల్లికి వందనం' పథకం కింద మీ ఇంట్లో ఎంతమంది పిల్లలు చదువుతుంటే వారందరికీ <br/>ఒక్కొక్కరికీ ఏడాదికి రూ.15,000లు అందిస్తాము.</li>
                                            <li>"దీపం" పథకం కింద ప్రతి కుటుంబానికి ఏడాదికి 3 గ్యాస్ సిలిండర్లను <br/>ఉచితంగా ఇస్తాము.</li>
                                            <li>"ఉచిత బస్సు ప్రయాణం" పథకం ద్వారా స్థానిక బస్సుల్లో మహిళలందరికీ <br/>టికెట్టులేని ప్రయాణ సౌకర్యం కలిగిస్తాము.</li>
                                        </ul>
                                        
                                    </div>
                                </div> 
                            </Col>
                        </Row>    
                </Container> 
            </section>
            <section className='bg_annaData pad_5rem'>
                <Container>
                        <Row>
                            <Col sm={12}>
                                <div className='flex-blocks-manifest-list '>
                                    <div className=''>
                                        <img src="https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/AnnaData_logo.png" alt="AnnaData_logo" className=' center-auto'></img>
                                        <ul className='list_type_css'>
                                            <li className='fw-bold'>ఈ అన్నదాత పథకం కింద ఆంధ్రప్రదేశ్ లోని<br/> ప్రతి రైతుకు ఏడాదికి 20,000 రూపాయల ఆర్థిక సాయం <br/> అందిస్తాము.</li>
                                        </ul>
                                        
                                    </div>
                                    <div><img src="https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/AnnaData_bg.png" alt="AnnaData_bg" className='w_res_100'></img></div>
                                </div> 
                            </Col>
                        </Row>    
                </Container> 
            </section>
            <section className='bg_YG pad_5rem'>
                <Container>
                        <Row>
                            <Col sm={12}>
                                <div className='flex-blocks-manifest-list '>
                                <div><img src="https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/YuvaGalam_bg.png" alt="YuvaGalam_bg" className='w_res_100'></img></div>
                                    <div className=''>
                                        <img src= "https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/YuvaGalam_logo.png" alt="YuvaGalam_logo" className=' center-auto'></img>
                                        <ul className='list_type_css'>
                                            <li className='fw-bold'>ఆంధ్రప్రదేశ్ లోని నిరుద్యోగులకు 20 లక్షల ఉద్యోగాలు <br/>ఇస్తాము.</li>
                                            <li className='fw-bold'>ప్రతి నిరుద్యోగికి 'యువగళం నిధి' కింద నెలకు 2500 రూపాయలను  <br/>ఇస్తాము.</li>
                                        </ul>
                                        
                                    </div>
                                </div> 
                            </Col>
                        </Row>    
                </Container> 
            </section>
            <section className='bg_HW pad_5rem'>
                <Container>
                        <Row>
                            <Col sm={12}>
                                <div className='flex-blocks-manifest-list '>
                                    <div className=''>
                                        <img src="https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/HW_logo.png" alt="HW_logo" className=' center-auto'></img>
                                        <ul className='list_type_css'>
                                            <li className='fw-bold'>చంద్రబాబుగారి ప్రభుత్వం అధికారంలోకి రాగానే  ఇంటింటికీ మంచి నీరు <br/> పథకం కింద ప్రతి ఇంటికీ కుళాయి కనెక్షన్  <br/>ఇస్తాము.</li>
                                        </ul>
                                        
                                    </div>
                                    <div><img src="https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/HW_bg.png" alt="HW_bg" className='w_res_100'></img></div>
                                </div> 
                            </Col>
                        </Row>    
                </Container> 
            </section>
            <section className='bg_PTR pad_5rem'>
                <Container>
                        <Row>
                            <Col sm={12}>
                                <div className='flex-blocks-manifest-list '>
                                <div><img src="https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/PTR_bg.png" alt="PTR_bg" className='w_res_100'></img></div>
                                    <div className=''>
                                        <img src="https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/PTR_logo.png" alt="PTR_logo" className=' center-auto'></img>
                                        <ul className='list_type_css'>
                                            <li className='fw-bold'>పేదలను సంపన్నులను చేస్తుంది.</li>
                                            <li className='fw-bold'>ఐదేళ్ళలో పేదల ఆదాయాన్ని రెట్టింపు చేస్తుంది.</li>
                                        </ul>
                                        
                                    </div>
                                </div> 
                            </Col>
                        </Row>    
                </Container> 
            </section>
            <section className='bg_BC pad_5rem'>
                <Container>
                        <Row>
                            <Col sm={12}>
                                <div className='flex-blocks-manifest-list '>
                                    <div className=''>
                                        <img src="https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/BC_logo.png" alt="BC_logo" className=' center-auto'></img>
                                        <ul className='list_type_css'>
                                            <li className='fw-bold'>బీసీలకు రక్షణ చట్టం తెచ్చి... వారికి అన్ని విధాలా అండగా నిలుస్తుంది <br/>తెలుగుదేశం పార్టీ.</li>
                                        </ul>
                                        
                                    </div>
                                    <div><img src="https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/BC_bg.png" alt="BC_bg" className='w_res_100'></img></div>
                                </div> 
                            </Col>
                        </Row>    
                </Container> 
            </section>
            <section className='bg_light_yellow pad_50'>
                {/*<Container>
                        <Row>
                            <Col>
                                <h1 className='text-center f-40 fw-bold'><span className='color_red'>సామాజిక మాధ్యమాలు</span></h1>
                            </Col>
                        </Row>
                </Container>
                <CuratorWidget feedId="ea307b82-4185-48ba-8e40-cc6eec7174e0" />*/}
                <Container>
                    <Row>
                        <Col>
                            <h1 className='text-center f-40 fw-bold mt-3'><span className='color_red'>సామాజిక మాధ్యమాలు</span></h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} className='mt-4'>
                        <Card className="text-center card-social-news">
                            <Card.Header className='bg_white fw-bold'>Follow us on Facebook <i class="fa-brands fa-facebook"></i></Card.Header>
                                <Card.Body>
                                    <iframe name="f23e9d67ae45ca" data-testid="fb:page Facebook Social Plugin" title="fb:page Facebook Social Plugin" allowtransparency="true" allowfullscreen="true" scrolling="no" allow="encrypted-media" className='fb_height_widtg'  src="https://www.facebook.com/v2.8/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df3710f0254c98f4%26domain%3Dtelugudesam.org%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Ftelugudesam.org%252Ff1688443123cf12%26relation%3Dparent.parent&amp;container_width=325&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FTDP.Official%2F&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width="  height="500" frameborder="0"></iframe>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={4} className='mt-4'>
                        <Card className="text-center card-social-news">
                            <Card.Header className='bg_white fw-bold'>Follow us on Twitter <i class="fa-brands fa-twitter"></i></Card.Header>
                                <Card.Body>
                                <iframe id="twitter-widget-0" scrolling="no" allowtransparency="true" allowfullscreen="true" class="twitter_height_width" title="Twitter Timeline" src="https://syndication.twitter.com/srv/timeline-profile/screen-name/JaiTDP?dnt=false&amp;embedId=twitter-widget-0&amp;features=eyJ0ZndfdGltZWxpbmVfbGlzdCI6eyJidWNrZXQiOltdLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2ZvbGxvd2VyX2NvdW50X3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19yZWZzcmNfc2Vzc2lvbiI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfZm9zbnJfc29mdF9pbnRlcnZlbnRpb25zX2VuYWJsZWQiOnsiYnVja2V0Ijoib24iLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X21peGVkX21lZGlhXzE1ODk3Ijp7ImJ1Y2tldCI6InRyZWF0bWVudCIsInZlcnNpb24iOm51bGx9LCJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3Nob3dfYmlyZHdhdGNoX3Bpdm90c19lbmFibGVkIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19kdXBsaWNhdGVfc2NyaWJlc190b19zZXR0aW5ncyI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdXNlX3Byb2ZpbGVfaW1hZ2Vfc2hhcGVfZW5hYmxlZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdmlkZW9faGxzX2R5bmFtaWNfbWFuaWZlc3RzXzE1MDgyIjp7ImJ1Y2tldCI6InRydWVfYml0cmF0ZSIsInZlcnNpb24iOm51bGx9LCJ0ZndfbGVnYWN5X3RpbWVsaW5lX3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9mcm9udGVuZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9fQ%3D%3D&amp;frame=false&amp;hideBorder=false&amp;hideFooter=false&amp;hideHeader=false&amp;hideScrollBar=false&amp;lang=en&amp;maxHeight=500px&amp;origin=https%3A%2F%2Ftelugudesam.org%2F&amp;sessionId=333c44b2fccb5c4b2c73a9f306315bb0d27a56db&amp;showHeader=true&amp;showReplies=false&amp;siteScreenName=JaiTDP&amp;transparent=false&amp;widgetsVersion=aaf4084522e3a%3A1674595607486" frameborder="0"></iframe>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={4} className='mt-4'>
                        <Card className="text-center card-social-news">
                            <Card.Header className='bg_white fw-bold'>Follow us on Instagram <i class="fa-brands fa-instagram"></i></Card.Header>
                                <Card.Body>
                                <iframe class="instagram-media instagram-media-rendered insta_height_widtg" id="instagram-embed-0" src="https://www.instagram.com/p/CfnKTgGBY41/embed/captioned/?cr=1&amp;v=14&amp;rd=https%3A%2F%2Ftelugudesam.org&amp;rp=%2F#%7B%22ci%22%3A0%2C%22os%22%3A727%7D" allowtransparency="true" allowfullscreen="true" data-instgrm-payload-id="instagram-media-payload-0"   frameborder="0"></iframe>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>  
            <Footer/>
            </>
        )
    }
  }
  
  export default withRouter(Home);
  