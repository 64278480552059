import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image } from "react-bootstrap";
import loader from '../../images/loader.gif';


class SuccessMessagePopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
      
    }
    render() {
        return (
            <Modal {...this.props} 
            size="sm" centered  aria-labelledby="example-modal-sizes-title-lg" className="">
                <Modal.Body className="">
                      <Row>
                         <Col>
                            <h2 className='text-center'><i class="fa-solid fa-circle-check color_green f-42"></i></h2>
                            <p className='mt-3  text-center fw-bold'>
                                Thanks For Registration...
                            </p>
                            <p className='mt-3 text-center f-18'>To Get Regular Updates Join Our Telegram Channel</p>
                            <Button variant="dark" className='f-12 float-end mt-2 btn-sm' onClick={() => this.props.closeSuccessModal()}>Close</Button> 
                         </Col>
                      </Row>
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default SuccessMessagePopup;
  

