
import React ,{ Component } from 'react';
import {Container, Row, Col,Button} from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./NavBar.css";
import { withRouter,Link } from "react-router-dom";
import TDPLOGO from '../images/tdp-logo.png';
import TDPLOGO1 from '../images/tdp-logo1.png';
import NTRLOGO from '../images/ntr-logo.png';

class NavBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            enrollId:this.props?.location?.params?.enrollId == undefined ? "" : this.props?.location?.params?.enrollId,
        }
    }
  
    componentDidMount() {
        if(this.props?.location?.params?.enrollId == "" ||  this.props?.location?.params?.enrollId == undefined){
            this.props.history.push({
                pathname: "/"
            }); 
        }
    }

    render() {
        console.log("ssss",this.props?.location?.params?.enrollId)
        return (
            <section className='bg_image_navbar'>
                <Navbar expand="lg">
                    <Container>
                        <Link to={{
                            pathname: "/",
                            
                            }}>
                            <Navbar.Brand ><img src={TDPLOGO} alt="TDPLOGO" className=''></img></Navbar.Brand>
                        </Link>
                        {
                            this.props?.location?.params?.enrollId == "" ||  this.props?.location?.params?.enrollId == undefined ?
                            (
                                <>
                                
                                <Link to={{
                                    pathname: "/login",
                                   
                                  }}>
                                  <Button variant="warning" className='login_btn_yellow fw-bold mobile_view_show ml_8rem'>లాగిన్</Button>
                                </Link>
                                </>
                            )
                            :
                            (
                                <>
                               
                                <Link to={{
                                    pathname: "/home",
                                    params: {
                                        enrollId:""
                                    },
                                  }}>
                                  <Button variant="warning" className='login_btn_yellow fw-bold mobile_view_show ml_8rem'>LogOut</Button>
                                </Link>
                                <Link to={{
                                    pathname: "/"
                                  }}>
                                  <Button variant="warning" className='login_btn_yellow fw-bold mobile_view_show ml_8rem'>Register</Button>
                                </Link>
                                </>
                            )
                        }
                       

                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                        <Nav.Link href="/home" className='active'>Home </Nav.Link>
                            
                            
                            {/*<Nav.Link href="#link">మన పార్టీ</Nav.Link>
                            <Nav.Link href="#home">సంక్షేమం</Nav.Link>
                            <Nav.Link href="#link">కార్యకర్తలు</Nav.Link>*/}
                        </Nav>
                        
                       
                        
                        <Navbar.Brand  className='mobile_view_none'><img src={TDPLOGO1} alt="TDPLOGONEW" className=''></img></Navbar.Brand>
                        <Navbar.Brand  className='mobile_view_none'><img src={NTRLOGO} alt="NTRLOGO" className=''></img></Navbar.Brand>

                        {
                            this.props?.location?.params?.enrollId == "" ||  this.props?.location?.params?.enrollId == undefined ?
                            (
                                <Link to={{
                                    pathname: "/login",
                                   
                                  }}>
                                  <Button variant="warning" className='login_btn_yellow fw-bold me-4 mobile_view_none'>లాగిన్</Button>
                                </Link>
                            )
                            :
                            (
                                <>
                                <Link to={{
                                    pathname: "/home",
                                    params: {
                                        enrollId:""
                                    },
                                   
                                  }}>
                                  <Button variant="warning" className='login_btn_yellow fw-bold me-4 mobile_view_none'>LogOut</Button>
                                </Link>
                                <Link to={{
                                    pathname: "/"
                                  }}>
                                  <Button variant="warning" className='login_btn_yellow fw-bold me-4 mobile_view_none'>Register</Button>
                                </Link>
                                </>
                            )
                        }

                        </Navbar.Collapse>
                    </Container>
                    </Navbar>
            </section>
        )
    }
  }
  
  export default withRouter(NavBar);
  