import React, { Component } from "react";
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css";
import NavBar from "./components/NavBar";
import Home from "./components/HomeSection/Home";
import LoginPageWithScanUrl from "./components/LoginPageWithScanUrl/LoginPageWithScanUrl";
import Login from "./components/Login/Login";

import ProfileDetails from "./components/ProfileDetails/ProfileDetails";
import ProfileDetailsWithOutScanUrl from "./components/ProfileDetails/ProfileDetailsWithOutScanUrl";
import NavBarLoginPageWithScanUrl from "./components/NavBarLoginPageWithScanUrl";

import PanchayatDetails from "./components/PanchayatDetailsSection/PanchayatDetails";
import NavBarLoginPagePanchayatRaj from "./components/NavBarLoginPagePanchayatRaj";
import LeaderSearchSection from "./components/LeaderSearch/LeaderSearchSection";
import LeaderDashboardSection from "./components/LeaderSearch/LeaderDashboardSection";
import LeaderNotMatchedSection from "./components/LeaderSearch/LeaderNotMatchedSection";
import {Helmet} from "react-helmet";

const currentUrl = window.location.href;
const queryParams = new URLSearchParams(window.location.search);
let enrollId = queryParams.get('i');
console.log("student_type",queryParams);

/*if(window.location.href == 'https://www.tdpmanifesto.com/panchayat_raj')
{
    window.location.href="https://t.me/+u7x_cbOPmPo0NjBl";
}*/


class App extends Component {
   

  render() {
    return (
        <>
        {
            currentUrl.includes('panchayat_raj') ? 
            (
                <>
                            <Router>
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>పంచాయతి రాజ్ మహోత్సవ్ ఉద్యమం</title>
                                <meta
                                    name="description"
                                    content="మీరు ప్రస్తుత లేదా మాజీ గ్రామా సర్పంచులు, వార్డ్ , మున్సిపల్ వార్డ్ మెంబెర్స్ , మునిసిపల్ ఛైర్మన్స్ , ఎంపీటీసీ , జడ్పీటీసీ , కార్పొరేషన్ డివిజన్ మెంబెర్స్ అయినవారు పంచాయతి రాజ్ మహోత్సవ్ ఉద్యమంలో మీరు భాగస్వామ్యులు అవ్వండి, ఈ కార్యక్రమం యొక్క ముఖ్య సమాచారం నిరంతరం తెలుసుకొనుట కోసం మీ వివరాలు తెలియజేయగలరు."
                                    />
                            </Helmet>
                                <Switch>
                                    <>
                                    <NavBarLoginPagePanchayatRaj/>
                                        <Route
                                            exact
                                            path= '/panchayat_raj'
                                            render={(props) => (
                                                <PanchayatDetails
                                                    {...props}
        
                                                />
                                            )}
                                        />
                                    </>
                                </Switch>
                            </Router>
                        </>
            )
            :
            (
                currentUrl.includes('leader_search') || currentUrl.includes('leader_dashboard') ? 
                (
                    <>
                    <Router>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Leaders Search</title>
                        <meta
                            name="description"
                            content="Leaders Search"
                            />
                    </Helmet>
                        <Switch>
                            <>
                                <Route
                                    exact
                                    path= '/leader_search'
                                    render={(props) => (
                                        <LeaderSearchSection
                                            {...props}

                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path= '/leader_dashboard'
                                    render={(props) => (
                                        <LeaderDashboardSection
                                            {...props}

                                        />
                                    )}
                                />
                                 <Route
                                    exact
                                    path= '/leader_notMatched_details'
                                    render={(props) => (
                                        <LeaderNotMatchedSection
                                            {...props}

                                        />
                                    )}
                                />
                            </>
                        </Switch>
                    </Router>
                </>
                )
                :
                (
                    <>
                        {
                            enrollId == null || enrollId == ""  ?
                            (
                                <>
                                    <Router>
                                        <Switch>
                                            <>
                                                <NavBar/>
                                                <Route
                                                    exact
                                                    path="/"
                                                    render={(props) => (
                                                        <Home
                                                            {...props}
                
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    path="/home"
                                                    render={(props) => (
                                                        <Home
                                                            {...props}
                
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    path="/login"
                                                    render={(props) => (
                                                        <Login
                                                            {...props}
                
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    path= '/profile_details_page'
                                                    render={(props) => (
                                                        <ProfileDetailsWithOutScanUrl
                                                            {...props}
                
                                                        />
                                                    )}
                                                />
                                            </>
                                        </Switch>
                                    </Router>
                                </>
                            )
                            :
                            (
                                <>
                                    <Router>
                                        <Switch>
                                            <>
                                            <NavBarLoginPageWithScanUrl/>
                                            <Route
                                                exact
                                                path="/"
                                                render={(props) => (
                                                    <LoginPageWithScanUrl
                                                        {...props}
                
                                                    />
                                                )}
                                            />
                                            <Route
                                                path= '/p'
                                                render={(props) => (
                                                    <ProfileDetails
                                                        {...props}
                
                                                    />
                                                )}
                                            />
                                            <Route
                                                    exact
                                                    path= '/pr'
                                                    render={(props) => (
                                                        <PanchayatDetails
                                                            {...props}
                
                                                        />
                                                    )}
                                                />
                                            </>
                                        </Switch>
                                    </Router>
                                </>
                            )
                        }
                        </>
                )
                
            )
        }
        
        </>
      
    )
  }
}
export default App;

