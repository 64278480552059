
import React ,{ Component } from 'react';
import {Container, Row, Col,Form,Button,Image,Card,Table,InputGroup} from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif'; 
import './LeaderSearch.css';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";


class LeaderDashboardSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            overAll:true,
            customDate:false,
            fromDate:moment().format("YYYY-MM-DD"),
        }
    }
  
    componentDidMount() {
    }

    datesWiseCountsShow = (value) => {
        if(value == "overAll"){
            this.setState({overAll:true,customDate:false})
        }else{
            this.setState({overAll:false,customDate:true})
        }
    }

    goToSearchPage = () => {
        this.props.history.push({
            pathname: `/leader_search`,
        }); 
    }

    notMatchedDetails = (date,type) => {
        this.props.history.push({
            pathname: `/leader_notMatched_details`,
            params: {
                dateSelected:date,
                selectedType:type
            },
        }); 
    }
    

    render() {
        return (
            <>
            <section className='leader_bg_color1'>
                <Container  className=''>
                    <Row>
                        <Col>
                            <img src={require('../../images/leadership-icon.png')} alt="leader-ship" className='w_80 center-auto mt-2'></img>
                            <h3 className='text-center color_white fw-bold font-style'>LEADERS SEARCH DASHBOARD</h3>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col sm={4}>
                            <Button variant="outline-light" className='f-12 float-end fw-bold  mb-2' onClick={() => this.goToSearchPage()}> HOME <i class="fa-solid fa-house"></i></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                   
                    <Row className='justify-content-center'>
                        <Col sm={4} className='mt-3'>
                            <div className='flex_programs_block'>
                                <div className={this.state.overAll == true ? "border_bottom_my cusor_pointer" : "cusor_pointer"} onClick={() => this.datesWiseCountsShow("overAll")}><h6 className='fw-bold f-14'><i class="fa-solid fa-gauge"></i> <span className='ms-2'>Over All</span></h6></div>
                                <div className={this.state.customDate == true ? "border_bottom_other cusor_pointer" : "cusor_pointer"} onClick={() => this.datesWiseCountsShow("custom")}><h6 className='fw-bold f-14'><i class="fa-solid fa-calendar-week"></i> <span className='ms-2'>Custom Date</span></h6></div>
                            </div>
                        </Col>
                     </Row>
                     {
                        this.state.overAll == true ?
                        (
                            <>
                            <Row className='justify-content-center'>
                                <Col sm={4}>
                                    <div className=''>
                                        <h6 className='f-12 fw-bold mt-2 mb-2'>OverAll :</h6>
                                        <Row>
                                            <Col>
                                                <div className='border_yellow'>
                                                    <h6 className='f-12 text-center'>Played&nbsp;Imaged</h6>
                                                    <h6 className='f-14 mt-2 text-center color_yellow fw-bold'>100</h6>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='border_yellow'>
                                                    <h6 className='f-12 text-center'>Matched</h6>
                                                    <h6 className='f-14 mt-2 text-center color_green fw-bold text-decoration-underline cursor_pointer' onClick={() => this.notMatchedDetails("OverAll","Matched")}>50</h6>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='border_yellow'>
                                                    <h6 className='f-12 text-center'>Not Matched</h6>
                                                    <h6 className='f-14 mt-2 text-center color_red fw-bold text-decoration-underline cursor_pointer' onClick={() => this.notMatchedDetails("OverAll","NotMatched")}>50</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='justify-content-center'>
                            <Col sm={4}>
                                <div className=''>
                                    <h6 className='f-12 fw-bold mt-2 mb-2'>Today :</h6>
                                    <Row>
                                        <Col>
                                            <div className='border_yellow'>
                                                <h6 className='f-12 text-center'>Played&nbsp;Imaged</h6>
                                                <h6 className='f-14 mt-2 text-center color_yellow fw-bold'>40</h6>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='border_yellow'>
                                                <h6 className='f-12 text-center'>Matched</h6>
                                                <h6 className='f-14 mt-2 text-center color_green fw-bold text-decoration-underline cursor_pointer' onClick={() => this.notMatchedDetails(moment().format("YYYY-MM-DD"),"Matched")}>20</h6>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='border_yellow'>
                                                <h6 className='f-12 text-center'>Not Matched</h6>
                                                <h6 className='f-14 mt-2 text-center color_red fw-bold text-decoration-underline cursor_pointer' onClick={() => this.notMatchedDetails(moment().format("YYYY-MM-DD"),"NotMatched")}>20</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row className='justify-content-center'>
                            <Col sm={4}>
                                <div className=''>
                                    <h6 className='f-12 fw-bold mt-2 mb-2'>Yesterday :</h6>
                                    <Row>
                                        <Col>
                                            <div className='border_yellow'>
                                                <h6 className='f-12 text-center'>Played&nbsp;Imaged</h6>
                                                <h6 className='f-14 mt-2 text-center color_yellow fw-bold'>60</h6>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='border_yellow'>
                                                <h6 className='f-12 text-center'>Matched</h6>
                                                <h6 className='f-14 mt-2 text-center color_green fw-bold text-decoration-underline cursor_pointer' onClick={() => this.notMatchedDetails(moment().subtract(1, 'day').format("YYYY-MM-DD"),"Matched")}>30</h6>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='border_yellow'>
                                                <h6 className='f-12 text-center'>Not Matched</h6>
                                                <h6 className='f-14 mt-2 text-center color_red fw-bold text-decoration-underline cursor_pointer' onClick={() => this.notMatchedDetails(moment().subtract(1, 'day').format('YYYY-MM-DD'),"NotMatched")}>30</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row className='justify-content-center'>
                            <Col sm={4}>
                                <div className=''>
                                    <h6 className='f-12 fw-bold mt-2 mb-2'>Last 7 Days :</h6>
                                    <Row>
                                        <Col>
                                            <div className='border_yellow'>
                                                <h6 className='f-12 text-center'>Played&nbsp;Imaged</h6>
                                                <h6 className='f-14 mt-2 text-center color_yellow fw-bold'>60</h6>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='border_yellow'>
                                                <h6 className='f-12 text-center'>Matched</h6>
                                                <h6 className='f-14 mt-2 text-center color_green fw-bold text-decoration-underline cursor_pointer' onClick={() => this.notMatchedDetails(moment().subtract(7, 'day').format("YYYY-MM-DD"),"Matched")}>30</h6>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='border_yellow'>
                                                <h6 className='f-12 text-center'>Not Matched</h6>
                                                <h6 className='f-14 mt-2 text-center color_red fw-bold text-decoration-underline cursor_pointer' onClick={() => this.notMatchedDetails(moment().subtract(7, 'day').format('YYYY-MM-DD'),"NotMatched")}>30</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        </>
                        )
                        :
                        ("")
                     }
                     {
                        this.state.customDate == true ?
                        (
                            <>
                            <Row className='justify-content-center'>
                                <Col sm={4}>
                                <h6 className="form-label f-12 fw-bold mt-2">Date </h6>
                                <DateRangePicker
                                        singleDatePicker={true}
                                        alwaysShowCalendars={true}
                                        onEvent={this.handleEvent}
                                        className="w-100"
                                    >   
                                    <div class="input-group">
                                            <div class="input-group-prepend bgColorYash">
                                            <i class="fa fa-calendar pad_5_15"></i>
                                            </div>
                                            <input type="text" className="form-control" value={moment(this.state.fromDate).format("YYYY-MM-DD")}/>
                                        </div>
                                    </DateRangePicker>
                                </Col>
                            </Row>

                            <Row className='justify-content-center'>
                            <Col sm={4}>
                                <div className=''>
                                    <h6 className='f-14 fw-bold mt-4 mb-3'>Date Wise ( {moment(this.state.fromDate).format("YYYY-MM-DD")} ) :</h6>
                                    <Row>
                                        <Col>
                                            <div className='border_yellow'>
                                                <h6 className='f-12 text-center'>Played&nbsp;Imaged</h6>
                                                <h6 className='f-14 mt-2 text-center color_yellow fw-bold'>60</h6>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='border_yellow'>
                                                <h6 className='f-12 text-center'>Matched</h6>
                                                <h6 className='f-14 mt-2 text-center color_green fw-bold text-decoration-underline cursor_pointer' onClick={() => this.notMatchedDetails(moment(this.state.fromDate).format("YYYY-MM-DD"),"Matched")}>30</h6>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='border_yellow'>
                                                <h6 className='f-12 text-center'>Not Matched</h6>
                                                <h6 className='f-14 mt-2 text-center color_red fw-bold text-decoration-underline cursor_pointer' onClick={() => this.notMatchedDetails(moment(this.state.fromDate).format("YYYY-MM-DD"),"NotMatched")}>30</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        </>
                        )
                        :
                        ("")
                     }
                </Container>
            </section>
            </>
        )
    }
  }
  
  export default withRouter(LeaderDashboardSection);
  