
import React ,{ Component } from 'react';
import {Container, Row, Col,Form,Button,Image,Card,Table,InputGroup} from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif'; 
import './LeaderSearch.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import { scroller } from "react-scroll";
import Correct_WrongPersonModal from './Correct_WrongPersonModal';
import { findAllByTestId } from '@testing-library/react';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

  
export async function getAPICallsForLocation(data,url) {
    
    const response  =    fetch('https://www.mypartydashboard.com/BSA/WebService/'+url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'user_data':""
        },
     body: data
  })

   return response;
}

class LeaderSearchSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            typeOfLeaderValue:"committeeMember",
            committeeLevelValue:"",
            typeOfLeaderBlock:true,

            committeeTypeValue:"main",
            committeeAffilatedNamesBlock:false,
            committeeTypeBlock:false,
            affilatedValue:"",
            affilatedName:"",
            af_listArr:[{'value':896444,'label':'Telugu Yuvatha'},{'value':896445,'label':'Telugu Mahila'}],

            committeeLevelBlock:false,

            leadersBlock:false,
            electionCommitteeBlock:false,

            parliamentValue:"",
            parliamentName:"",
            pc_listArr:[],

            mandalValue:"",
            mandalName:"",
            mc_listArr:[{'value':1,'label':'Allavaram'},{'value':2,'label':'Amalapuram'},{'value':3,'label':'Uppalaguptam'}],

            villageValue:"",
            villageName:"",
            vc_listArr:[{'value':1,'label':'Allavaram'},{'value':2,'label':'Bendamurlanka'},{'value':3,'label':'Bodasakurru'}],

            clusterValue:"",
            clusterName:"",
            cluster_listArr:[{'value':1,'label':'CLUSTER_001'},{'value':2,'label':'CLUSTER_002'},{'value':3,'label':'CLUSTER_003'},{'value':4,'label':'CLUSTER_004'},{'value':5,'label':'CLUSTER_005'}],

            unitValue:"",
            unitName:"",
            unit_listArr:[{'value':1,'label':'UNIT_001'},{'value':2,'label':'UNIT_002'},{'value':3,'label':'UNIT_003'},{'value':4,'label':'UNIT_004'},{'value':5,'label':'UNIT_005'}],

            boothValue:"",
            boothName:"",
            booth_listArr:[{'value':1,'label':'BoothNo-1'},{'value':2,'label':'BoothNo-2'},{'value':3,'label':'BoothNo-3'},{'value':4,'label':'BoothNo-4'},{'value':5,'label':'BoothNo-5'}],

            onChangeECName:"",

            constituencyValue:"",
            constituencyName:"",
            ac_listArr:[{'value':108,'label':'Tekkali'}],

            photosShowBlock:false,
            checkCorrectNameBlock:false,
            checkPhotoValue:"",
            showCorrectPersonInModalBlock:false,

            checkCorrectPersonModal:false,
            checkSelectedPersonName:"",
            checkSelectedMatchedType:"",
            
            checkPersonTick1:false,
            checkPersonTick2:false,
            checkPersonTick3:false,
            checkPersonTick4:false,
            checkPersonTick5:false,
           
            
        }
    }
  
    componentDidMount() {
        AOS.init();
        this.getParliamentConstituencies();
        this.getAssemblyConstituenciesByParliamentId(0)
    }

    getParliamentConstituencies = () => {
        const obj =  
        JSON.stringify({ 
            "constituencyId" : 0,
        }); 

        getAPICallsForLocation(obj,"getParliamentConstituencies")
        .then((res) => res.json())
        .then((data) => {
          if(data !=null && data.length > 0){
            let parlArr=[];
            data.map(item => {
                const obj = {
                    value: item.id,
                    label: item.name,
                }
                parlArr.push(obj);
                
            });
            this.setState({ pc_listArr: parlArr });

          }else{
            this.setState({ pc_listArr:[]});
          }
        });
    }
    getAssemblyConstituenciesByParliamentId = (locationId) => {
        const obj =  
        JSON.stringify({ 
            "locationId" : locationId,
        }); 

        getAPICallsForLocation(obj,"getAssemblyConstituenciesByParliamentId")
        .then((res) => res.json())
        .then((data) => {
          if(data !=null && data.length > 0){
            let parlArr=[];
            data.map(item => {
                const obj = {
                    value: item.id,
                    label: item.name,
                }
                parlArr.push(obj);
                
            });
            this.setState({ ac_listArr: parlArr });

          }else{
            this.setState({ ac_listArr:[]});
          }
        });
    }

    getMandalsAndTownsByConstituencyId = (locationId) => {
        const obj =  
        JSON.stringify({ 
            "locationId" : locationId,
        }); 

        getAPICallsForLocation(obj,"getMandalsAndTownsByConstituencyId")
        .then((res) => res.json())
        .then((data) => {
          if(data !=null && data.length > 0){
            let parlArr=[];
            data.map(item => {
                const obj = {
                    value: item.id,
                    label: item.name,
                }
                parlArr.push(obj);
                
            });
            this.setState({ mc_listArr: parlArr });

          }else{
            this.setState({ mc_listArr:[]});
          }
        });
    }

    typeOfLeaderChange = (e,value) => {
        this.setState({typeOfLeaderValue:value})
    }

    nextSlideBlockLeaderType = () => {
        this.setState({parliamentValue:"",parliamentName:"",constituencyName:"",constituencyValue:""})
        if(this.state.typeOfLeaderValue == "committeeMember"){
            this.setState({committeeTypeBlock:true,typeOfLeaderBlock:false,onChangeECName:"Party Committee"})
        }else if(this.state.typeOfLeaderValue == "leader"){
            this.setState({leadersBlock:true,typeOfLeaderBlock:false,onChangeECName:"Leaders"})
        }else{
            this.getAssemblyConstituenciesByParliamentId(0)
            this.setState({electionCommitteeBlock:true,typeOfLeaderBlock:false,onChangeECName:""})
        }
    }

   

    // Leaders Block 

    previousSlideBlockLeadersType = () => {
        this.setState({typeOfLeaderBlock:true,leadersBlock:false,typeOfLeaderValue:"committeeMember"})
    }

     //commitee Level Block

     previousSlideBlockTypeOfLeader = () => {
        this.setState({committeeTypeBlock:false,typeOfLeaderBlock:true})
    }
    //commitee Type Block

    committeeTypeBlockOnchange = (e,value) => {
        this.setState({committeeTypeValue:value})
    }
    nextSlideBlockCommitteeType = () => {
        this.setState({typeOfLeaderBlock:false,committeeTypeBlock:false})
        if(this.state.committeeTypeValue == "main"){
            this.setState({committeeLevelBlock:true})
        }else{
            this.setState({committeeAffilatedNamesBlock:true})
        }
        
    }

    previousSlideBlockCommitteeType = () => {
        
        this.setState({typeOfLeaderBlock:false,committeeTypeBlock:true,committeeLevelBlock:false,committeeLevelValue:"",committeeTypeValue:"main",photosShowBlock:false})
    }

    committeeLevelChange = (e,value) => {
        this.setState({showCorrectPersonInModalBlock:false})
        this.setState({checkPhotoValue:"",checkPersonValue:"",checkSelectedPersonName:"",checkSelectedMatchedType:"",checkCorrectNameBlock:false})

        this.setState({parliamentValue:"",parliamentName:"",constituencyName:"",constituencyValue:"",mandalValue:"",mandalName:"",villageValue:"",villageName:""})
        this.setState({committeeLevelValue:value,photosShowBlock:false});
        if(value == "state"){
            this.setState({photosShowBlock:true})
        }
    }

    nextSlideBlockCommitteeAffilatedType = () => {
        this.setState({committeeAffilatedNamesBlock:false,committeeLevelBlock:true})
    }

    previousSlideBlockCommitteeAff = () => {
        
        this.setState({committeeAffilatedNamesBlock:false,committeeTypeBlock:true,committeeTypeValue:"main"})
    }

    handleOnchangeSelectBoxNew = (ename, evalue) =>{
        const name = ename;
        const value = evalue;
        this.setState({photosShowBlock:false});
        this.setState({showCorrectPersonInModalBlock:false})
        this.setState({checkPhotoValue:"",checkPersonValue:"",checkSelectedPersonName:"",checkSelectedMatchedType:"",checkCorrectNameBlock:false})

        if(name == "parliamentName"){
            this.setState({constituencyName:"",constituencyValue:""})
            if(this.state.committeeLevelValue == "parliament"){
                this.setState({photosShowBlock:true})
            }
            
            let getParIdName = this.state.pc_listArr.find((a) => a.value == value);
                this.setState({
                    parliamentValue: {
                    value: getParIdName.value,
                    label: getParIdName.label
                }
            });
            this.getAssemblyConstituenciesByParliamentId(value);

        }else if(name == "constituencyName"){
            if(this.state.committeeLevelValue != "mandal" && this.state.committeeLevelValue != "village"){
                this.setState({photosShowBlock:true})
            }else if(this.state.committeeLevelValue == "mandal" || this.state.committeeLevelValue == "village"){
                this.setState({mandalValue:"",mandalName:"",villageValue:"",villageName:""})
            }
            let getConsIdName = this.state.ac_listArr.find((a) => a.value == value);
            this.setState({
                constituencyValue: {
                value: getConsIdName.value,
                label: getConsIdName.label
            }
        });
        //this.getMandalsAndTownsByConstituencyId(value)
           
        }else if(name == "affilatedName"){
            let getAffIdName = this.state.af_listArr.find((a) => a.value == value);
            this.setState({
                affilatedValue: {
                value: getAffIdName.value,
                label: getAffIdName.label
            }
        });
           
        }else if(name == "clusterName"){
            this.setState({unitValue:"",unitName:""})
            let getclusIdName = this.state.cluster_listArr.find((a) => a.value == value);
            this.setState({
                clusterValue: {
                value: getclusIdName.value,
                label: getclusIdName.label
            }
        });
        this.setState({photosShowBlock:true})

        this.setState({onChangeECName:"Cluster Incharge Details"})
           
        }else if(name == "unitName"){
            this.setState({clusterValue:"",clusterName:""})
            let getunutIdName = this.state.unit_listArr.find((a) => a.value == value);
            this.setState({
                unitValue: {
                value: getunutIdName.value,
                label: getunutIdName.label
            }
        });
        this.setState({photosShowBlock:true})
        this.setState({onChangeECName:"Unit Incharge Details"})
           
        }else if(name == "boothName"){
            this.setState({clusterValue:"",clusterName:"",unitValue:"",unitName:""})
            let getclusIdName = this.state.booth_listArr.find((a) => a.value == value);
            this.setState({
                boothValue: {
                value: getclusIdName.value,
                label: getclusIdName.label
            }
        });
        this.setState({photosShowBlock:true})
        this.setState({onChangeECName:"Booth Incharge Details"})
           
        }else if(name == "mandalName"){
            if(this.state.committeeLevelValue == "village"){
                this.setState({villageValue:"",villageName:""})
            }else if(this.state.committeeLevelValue == "mandal"){
                this.setState({mandalValue:"",mandalName:""})
            }
            let getManIdName = this.state.mc_listArr.find((a) => a.value == value);
            this.setState({
                mandalValue: {
                value: getManIdName.value,
                label: getManIdName.label
            }
        });
        if(this.state.committeeLevelValue == "mandal"){
        this.setState({photosShowBlock:true})
        }
           
        }else if(name == "villageName"){
            let getVillIdName = this.state.vc_listArr.find((a) => a.value == value);
            this.setState({
                villageValue: {
                value: getVillIdName.value,
                label: getVillIdName.label
            }
        });
        this.setState({photosShowBlock:true})
           
        }
          
    
        this.setState({ [name]: value});
      }

      
      checkPhotoClick = (value) => {
        scroller.scrollTo("scroll_top", { 
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
        });

        this.setState({showCorrectPersonInModalBlock:false})
        this.setState({checkPersonValue:"",checkSelectedPersonName:"",checkSelectedMatchedType:""})
        
        this.setState({checkPhotoValue:value,checkCorrectNameBlock:true})

      }

      checkCorrectPersonIdentified = (e,value,name,matchedType) => {
        this.setState({showCorrectPersonInModalBlock:false})
        this.setState({checkCorrectPersonModal:true,checkPersonValue:value,checkSelectedPersonName:name,checkSelectedMatchedType:matchedType})
      }

      showCorrectPersonInModalBlock = () => {
        this.setState({showCorrectPersonInModalBlock:true})
      }
      

      gotoHome = () => {
        this.setState({typeOfLeaderValue:"committeeMember",
        committeeLevelValue:"",
        typeOfLeaderBlock:true,

        committeeTypeValue:"main",
        committeeAffilatedNamesBlock:false,
        committeeTypeBlock:false,
        affilatedValue:"",
        affilatedName:"",

        committeeLevelBlock:false,

        leadersBlock:false,
        electionCommitteeBlock:false,

        mandalValue:"",
        mandalName:"",

        villageValue:"",
        villageName:"",

        parliamentValue:"",
        parliamentName:"",

        clusterValue:"",
        clusterName:"",

        unitValue:"",
        unitName:"",

        boothValue:"",
        boothName:"",

        onChangeECName:"",

        constituencyValue:"",
        constituencyName:"",

        photosShowBlock:false,
        checkCorrectNameBlock:false,
        checkPhotoValue:"",
        showCorrectPersonInModalBlock:false,

        checkCorrectPersonModal:false,
        checkSelectedPersonName:"",
        checkSelectedMatchedType:"",
        
        checkPersonTick1:false,
        checkPersonTick2:false,
        checkPersonTick3:false,
        checkPersonTick4:false,
        checkPersonTick5:false,
        
        
        })
      }

      leadersDashboardPage = () => {
        this.props.history.push({
            pathname: `/leader_dashboard`,
        }); 
      }

      closePersonModal = () => {
        this.setState({checkCorrectPersonModal:false});
        this.setState({checkPersonTick1:false,
            checkPersonTick2:false,
            checkPersonTick3:false,
            checkPersonTick4:false,
            checkPersonTick5:false})
      }

    render() {
        return (
            <section className='leader_bg_color'>
                <Container fluid className='red_bg_color'>
                    <Row>
                        <Col>
                            <img src={require('../../images/leadership-icon.png')} alt="leader-ship" className='w_80 center-auto mt-2'></img>
                            <h3 className='text-center color_white fw-bold font-style'>LEADERS SEARCH</h3>
                        </Col>
                    </Row>
                    {
                        this.state.electionCommitteeBlock != true && this.state.leadersBlock != true && this.state.committeeLevelBlock != true ?
                        (
                            <Row className='justify-content-center'>
                                <Col sm={4}>
                                    <Button variant="outline-light" className='f-12 float-end fw-bold mt-2' onClick={() => this.leadersDashboardPage()}><i class="fa-solid fa-gauge"></i> Dashboard</Button>
                                </Col>
                            </Row>
                        )
                        :
                        ("")
                    }
                    
                    {
                        this.state.typeOfLeaderBlock == true ?
                        (
                            <>
                            <div data-aos="slide-right" data-aos-delay="100">
                                <Row className='justify-content-center'>
                                    <Col sm={4}>
                                        <Card className='mt-2'>
                                            <Card.Body className='pad_20_40'>
                                                <h6 className='text-center f-18 fw-bold'>Type Of Leader</h6>
                                                <div className=" mt-3">
                                                    <Form.Check
                                                            inline
                                                            label={"Party Committee"}
                                                            name="typeofLeaderValue"
                                                            type="radio"
                                                            id={`typeofLeaderValueCM`}
                                                            className="input_style"
                                                            defaultChecked={true}
                                                            value="committeeMember"
                                                            onChange={(e)=>this.typeOfLeaderChange(e,"committeeMember")}
                                                    ></Form.Check>
                                                    <Form.Check
                                                            inline
                                                            label={"Leaders"}
                                                            name="typeofLeaderValue"
                                                            type="radio"
                                                            id={`typeofLeaderValueL`}
                                                            className="input_style mt-1"
                                                            value="leader"
                                                            onChange={(e)=>this.typeOfLeaderChange(e,"leader")}
                                                    ></Form.Check>
                                                     <Form.Check
                                                            inline
                                                            label={"Election Committee"}
                                                            name="typeofLeaderValue"
                                                            type="radio"
                                                            id={`typeofLeaderValueE`}
                                                            className="input_style mt-1"
                                                            value="election"
                                                            onChange={(e)=>this.typeOfLeaderChange(e,"election")}
                                                    ></Form.Check>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                            
                            <Row className='justify-content-center'>
                                <Col sm={4}>
                                    <Button variant="warning" className='fw-bold float-end mt-2 f-12' onClick={() => this.nextSlideBlockLeaderType()}>NEXT <i class="fa-solid fa-arrow-right"></i></Button>
                                </Col>
                            </Row>
                            </>
                        )
                        :
                        ("")
                    }
                    {
                        this.state.electionCommitteeBlock == true ?
                        (
                            <>
                            <Row className='justify-content-center'>
                                <Col sm={4}>
                                     <Button variant="outline-light" className='f-12 mt-3 ml-2' onClick={() => this.gotoHome()}> Home <i class="fa-solid fa-house"></i> </Button>
                                     <Button variant="outline-light" className='f-12 float-end fw-bold mt-3' onClick={() => this.leadersDashboardPage()}><i class="fa-solid fa-gauge"></i> Dashboard</Button>
                                     
                                </Col>
                            </Row>
                            <div >
                                <Row className='justify-content-center'>
                                    <Col sm={4}>
                                        <Card className='mt-2'>
                                            <Card.Body className='pad_20_13'>
                                                <h6 className='f-14 fw-bold'>Election Committee - Mangalagiri Assembly</h6>
                                                <Row>
                                                <Col className=''>
                                                        <label className="form-label f-12 fw-bold">Cluster</label>
                                                        <SelectBox
                                                                stateData={this.state.clusterValue}
                                                                handleChange={this.handleOnchangeSelectBoxNew}
                                                                name="clusterName"
                                                                options={this.state.cluster_listArr}
                                                                placeholderName={'Cluster'}
                                                                dropdownIndicator={{ DropdownIndicator }}
                                                            />
                                                    </Col>
                                                    <Col className=''>
                                                            <label className="form-label f-12 fw-bold">Unit</label>
                                                            <SelectBox
                                                                    stateData={this.state.unitValue}
                                                                    handleChange={this.handleOnchangeSelectBoxNew}
                                                                    name="unitName"
                                                                    options={this.state.unit_listArr}
                                                                    placeholderName={'Unit'}
                                                                    dropdownIndicator={{ DropdownIndicator }}
                                                                />
                                                        </Col>
                                                </Row>
                                                <Row>
                                                <Col className='' sm={4}>
                                                        <label className="form-label f-12 fw-bold">Booth</label>
                                                        <SelectBox
                                                                stateData={this.state.boothValue}
                                                                handleChange={this.handleOnchangeSelectBoxNew}
                                                                name="boothName"
                                                                options={this.state.booth_listArr}
                                                                placeholderName={'Booth'}
                                                                dropdownIndicator={{ DropdownIndicator }}
                                                            />
                                                    </Col>
                                                   
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                           
                            </>
                        )
                        :
                        ("")
                    }
                    {
                        this.state.leadersBlock == true ?
                        (
                            <>
                            <Row className='justify-content-center'>
                                <Col sm={4}>
                                     <Button variant="outline-light" className='f-12 mt-3 ml-2' onClick={() => this.gotoHome()}> Home <i class="fa-solid fa-house"></i> </Button>
                                     <Button variant="outline-light" className='f-12 float-end fw-bold mt-3' onClick={() => this.leadersDashboardPage()}><i class="fa-solid fa-gauge"></i> Dashboard</Button>
                                     {/*<Button variant="outline-light" className='f-12 mt-3' onClick={() => this.previousSlideBlockLeadersType()}><i class="fa-solid fa-arrow-left"></i> BACK </Button>*/}
                                     
                                </Col>
                            </Row>
                            <div className=''>
                                <div >
                                    <Row className='justify-content-center'>
                                        <Col sm={4}>
                                            <Card className='mt-2'>
                                                <Card.Body className='pad_20_13'>
                                                    <h6 className='f-18 fw-bold'>AC Incharges / Observers</h6>
                                                    <Row>
                                                        <Col className='mt-1 mb-2'>
                                                            <label className="form-label f-12 fw-bold">Parliament</label>
                                                            <SelectBox
                                                                    stateData={this.state.parliamentValue}
                                                                    handleChange={this.handleOnchangeSelectBoxNew}
                                                                    name="parliamentName"
                                                                    options={this.state.pc_listArr}
                                                                    placeholderName={'Parliament'}
                                                                    dropdownIndicator={{ DropdownIndicator }}
                                                                />
                                                        </Col>
                                                        <Col className='mt-1 mb-2'>
                                                                <label className="form-label f-12 fw-bold">Constituency</label>
                                                                <SelectBox
                                                                        stateData={this.state.constituencyValue}
                                                                        handleChange={this.handleOnchangeSelectBoxNew}
                                                                        name="constituencyName"
                                                                        options={this.state.ac_listArr}
                                                                        placeholderName={'Constituency'}
                                                                        dropdownIndicator={{ DropdownIndicator }}
                                                                    />
                                                            </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            
                           
                            </>
                        )
                        :
                        ("")
                    }
                    {
                        this.state.committeeTypeBlock == true ? 
                        (
                            <>
                            <div  data-aos="slide-left" data-aos-delay="100">
                                <Row className='justify-content-center'>
                                        <Col sm={4}>
                                            <Card className='mt-2'>
                                                <Card.Body className='pad_20_40'>
                                                    <h6 className='text-center f-18 fw-bold'>Committee Type</h6>
                                                    <Row>
                                                        <Col className=''>
                                                            <Form.Check
                                                                    inline
                                                                    label={"Main"}
                                                                    name="committeeType"
                                                                    type="radio"
                                                                    id={`committeeTypeMain`}
                                                                    className="input_style"
                                                                    defaultChecked={true}
                                                                    value="main"
                                                                    onChange={(e)=>this.committeeTypeBlockOnchange(e,"main")}
                                                            ></Form.Check>
                                                            <Form.Check
                                                                    inline
                                                                    label={"Affilated"}
                                                                    name="committeeType"
                                                                    type="radio"
                                                                    id={`committeeTypeAff`}
                                                                    className="input_style mt-1"
                                                                    value="affilated"
                                                                    onChange={(e)=>this.committeeTypeBlockOnchange(e,"affilated")}
                                                            ></Form.Check>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                            </div>
                            <Row className='justify-content-center'>
                                <Col sm={4}>
                                    <Button variant="warning" className='fw-bold float-end mt-2 f-12' onClick={() => this.nextSlideBlockCommitteeType()}>NEXT <i class="fa-solid fa-arrow-right"></i></Button>
                                    <Button variant="light" className='fw-bold float-end mt-2 ml-2 f-12' onClick={() => this.previousSlideBlockTypeOfLeader()}><i class="fa-solid fa-arrow-left"></i> BACK </Button>
                                </Col>
                            </Row>
                            </>
                        )
                        :
                        ("")
                    }
                    {
                        this.state.committeeAffilatedNamesBlock == true ?
                        (
                            <>
                            <div data-aos="slide-left" data-aos-delay="100">
                                <Row className='justify-content-center'>
                                        <Col sm={4}>
                                            <Card className='mt-2'>
                                                <Card.Body className='pad_20_40'>
                                                    <Row>
                                                        <Col className='mt-3 mb-2'>
                                                            <label className="form-label f-12 fw-bold">Select Affilated Committees</label>
                                                            <SelectBox
                                                                    stateData={this.state.affilatedValue}
                                                                    handleChange={this.handleOnchangeSelectBoxNew}
                                                                    name="affilatedName"
                                                                    options={this.state.af_listArr}
                                                                    placeholderName={'Affilated'}
                                                                    dropdownIndicator={{ DropdownIndicator }}
                                                                />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                </Row>
                            </div>
                            <Row className='justify-content-center'>
                            <Col sm={4}>
                                <Button variant="warning" className='fw-bold float-end mt-2 f-12' onClick={() => this.nextSlideBlockCommitteeAffilatedType()}>NEXT <i class="fa-solid fa-arrow-right"></i></Button>
                                <Button variant="light" className='fw-bold float-end mt-2 ml-2 f-12' onClick={() => this.previousSlideBlockCommitteeAff()}><i class="fa-solid fa-arrow-left"></i> BACK </Button>
                            </Col>
                        </Row>
                        </>
                        )
                        :
                        ("")
                    }
                    {
                        this.state.committeeLevelBlock == true ? 
                        (
                            <>
                             <Row className='justify-content-center'>
                                <Col sm={4}>
                                     <Button variant="outline-light" className='f-12 mt-3 ml-2' onClick={() => this.gotoHome()}> Home <i class="fa-solid fa-house"></i> </Button>
                                     <Button variant="outline-light" className='f-12 mt-3' onClick={() => this.previousSlideBlockCommitteeType()}><i class="fa-solid fa-arrow-left"></i> BACK </Button>
                                     <Button variant="outline-light" className='f-12 float-end fw-bold mt-3' onClick={() => this.leadersDashboardPage()}><i class="fa-solid fa-gauge"></i> Dashboard</Button>
                                </Col>
                            </Row>
                            <div  data-aos="slide-left" data-aos-delay="100">
                                <Row className='justify-content-center'>
                                        <Col sm={4}>
                                            <Card className='mt-1'>
                                                <Card.Body className='pad_20_13'>
                                                    <h6 className='text-center f-18 fw-bold'>Committee Level</h6>
                                                    <Row>
                                                        <Col className=''>
                                                            <Form.Check
                                                                    inline
                                                                    label={"State"}
                                                                    name="committeeLevel"
                                                                    type="radio"
                                                                    id={`stateCommitteeLevelId`}
                                                                    className="input_style"
                                                                    onChange={(e)=>this.committeeLevelChange(e,"state")}
                                                                    value="state"
                                                            ></Form.Check>
                                                            <Form.Check
                                                                    inline
                                                                    label={"Parliament"}
                                                                    name="committeeLevel"
                                                                    type="radio"
                                                                    id={`parliamentCommitteeLevelId`}
                                                                    className="input_style mt-1"
                                                                    onChange={(e)=>this.committeeLevelChange(e,"parliament")}
                                                                    value="parliament"
                                                            ></Form.Check>
                                                            <Form.Check
                                                                    inline
                                                                    label={"Assembly"}
                                                                    name="committeeLevel"
                                                                    type="radio"
                                                                    id={`assemblyCommitteeLevelId`}
                                                                    className="input_style mt-1"
                                                                    onChange={(e)=>this.committeeLevelChange(e,"assembly")}
                                                                    value="assembly"
                                                            ></Form.Check>
                                                            <Form.Check
                                                                inline
                                                                label={"Mandal/Town/Division"}
                                                                name="committeeLevel"
                                                                type="radio"
                                                                id={`mandalCommitteeLevelId`}
                                                                className="input_style mt-1"
                                                                onChange={(e)=>this.committeeLevelChange(e,"mandal")}
                                                                value="mandal"
                                                        ></Form.Check>
                                                        <Form.Check
                                                                inline
                                                                label={"Village/Ward"}
                                                                name="committeeLevel"
                                                                type="radio"
                                                                id={`villageCommitteeLevelId`}
                                                                className="input_style mt-1"
                                                                onChange={(e)=>this.committeeLevelChange(e,"village")}
                                                                value="village"
                                                        ></Form.Check>
                                                        </Col>
                                                    </Row>
                                                    
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                            </div>
                            {
                                this.state.committeeLevelValue == "parliament" ?
                                (
                                    <div className='zIndexCls'>
                                        <Row className='justify-content-center'>
                                            <Col className='mt-2 mb-2' sm={4}>
                                                <label className="form-label f-12 fw-bold color_white">Parliament</label>
                                                <SelectBox
                                                        stateData={this.state.parliamentValue}
                                                        handleChange={this.handleOnchangeSelectBoxNew}
                                                        name="parliamentName"
                                                        options={this.state.pc_listArr}
                                                        placeholderName={'Parliament'}
                                                        dropdownIndicator={{ DropdownIndicator }}
                                                    />
                                            </Col>
                                            
                                        </Row>
                                    </div>
                                )
                                :
                                (
                                    this.state.committeeLevelValue == "assembly" ?
                                    (
                                        <div  className='zIndexCls'>
                                            <Row className='justify-content-center'>
                                                <Col sm={4}>
                                                    <Row>
                                                        <Col className='mt-1' >
                                                            <label className="form-label f-12 fw-bold color_white">Parliament</label>
                                                            <SelectBox
                                                                    stateData={this.state.parliamentValue}
                                                                    handleChange={this.handleOnchangeSelectBoxNew}
                                                                    name="parliamentName"
                                                                    options={this.state.pc_listArr}
                                                                    placeholderName={'Parliament'}
                                                                    dropdownIndicator={{ DropdownIndicator }}
                                                                />
                                                    </Col>
                                                    <Col className='mt-1' >
                                                        <label className="form-label f-12 fw-bold color_white">Constituency</label>
                                                        <SelectBox
                                                                stateData={this.state.constituencyValue}
                                                                handleChange={this.handleOnchangeSelectBoxNew}
                                                                name="constituencyName"
                                                                options={this.state.ac_listArr}
                                                                placeholderName={'Constituency'}
                                                                dropdownIndicator={{ DropdownIndicator }}
                                                            />
                                                    </Col>
                                                    </Row>
                                                </Col>
                                                
                                                
                                            </Row>
                                        </div>
                                    )
                                    :
                                    (
                                        this.state.committeeLevelValue == "mandal"  ? 
                                            (
                                                <div  className='zIndexCls'>
                                                    <Row className='justify-content-center'>
                                                        <Col sm={4}>
                                                            <Row>
                                                            <Col className='mt-1' >
                                                                <label className="form-label f-12 fw-bold color_white">Parliament</label>
                                                                <SelectBox
                                                                        stateData={this.state.parliamentValue}
                                                                        handleChange={this.handleOnchangeSelectBoxNew}
                                                                        name="parliamentName"
                                                                        options={this.state.pc_listArr}
                                                                        placeholderName={'Parliament'}
                                                                        dropdownIndicator={{ DropdownIndicator }}
                                                                    />
                                                            </Col>
                                                            <Col className='mt-1' >
                                                                <label className="form-label f-12 fw-bold color_white">Constituency</label>
                                                                <SelectBox
                                                                        stateData={this.state.constituencyValue}
                                                                        handleChange={this.handleOnchangeSelectBoxNew}
                                                                        name="constituencyName"
                                                                        options={this.state.ac_listArr}
                                                                        placeholderName={'Constituency'}
                                                                        dropdownIndicator={{ DropdownIndicator }}
                                                                    />
                                                            </Col>
                                                            </Row>
                                                        </Col>
                                                        
                                                    </Row>

                                                    <Row className='justify-content-center'>
                                                        <Col sm={4}>
                                                            <Row>
                                                            <Col className='mt-1' >
                                                                <label className="form-label f-12 fw-bold color_white">Mandal/Town</label>
                                                                <SelectBox
                                                                        stateData={this.state.mandalValue}
                                                                        handleChange={this.handleOnchangeSelectBoxNew}
                                                                        name="mandalName"
                                                                        options={this.state.mc_listArr}
                                                                        placeholderName={'Mandal/Town'}
                                                                        dropdownIndicator={{ DropdownIndicator }}
                                                                    />
                                                            </Col>
                                                            </Row>
                                                        </Col>
                                                        
                                                    </Row>
                                                   
                                                </div>
                                            )
                                            :
                                            (
                                                this.state.committeeLevelValue == "village" ? 
                                                (
                                                    <div  className='zIndexCls'>
                                                    <Row className='justify-content-center'>
                                                        <Col sm={4}>
                                                            <Row>
                                                            <Col className='mt-1' >
                                                                <label className="form-label f-12 fw-bold color_white">Parliament</label>
                                                                <SelectBox
                                                                        stateData={this.state.parliamentValue}
                                                                        handleChange={this.handleOnchangeSelectBoxNew}
                                                                        name="parliamentName"
                                                                        options={this.state.pc_listArr}
                                                                        placeholderName={'Parliament'}
                                                                        dropdownIndicator={{ DropdownIndicator }}
                                                                    />
                                                            </Col>
                                                            <Col className='mt-1' >
                                                                <label className="form-label f-12 fw-bold color_white">Constituency</label>
                                                                <SelectBox
                                                                        stateData={this.state.constituencyValue}
                                                                        handleChange={this.handleOnchangeSelectBoxNew}
                                                                        name="constituencyName"
                                                                        options={this.state.ac_listArr}
                                                                        placeholderName={'Constituency'}
                                                                        dropdownIndicator={{ DropdownIndicator }}
                                                                    />
                                                            </Col>
                                                            </Row>
                                                        </Col>
                                                        
                                                    </Row>
                                                    <Row className='justify-content-center'>
                                                        <Col sm={4}>
                                                            <Row>
                                                            <Col className='mt-1' >
                                                                <label className="form-label f-12 fw-bold color_white">Mandal/Town</label>
                                                                <SelectBox
                                                                        stateData={this.state.mandalValue}
                                                                        handleChange={this.handleOnchangeSelectBoxNew}
                                                                        name="mandalName"
                                                                        options={this.state.mc_listArr}
                                                                        placeholderName={'Mandal/Town'}
                                                                        dropdownIndicator={{ DropdownIndicator }}
                                                                    />
                                                            </Col>
                                                            <Col className='mt-1' >
                                                                <label className="form-label f-12 fw-bold color_white">Village</label>
                                                                <SelectBox
                                                                        stateData={this.state.villageValue}
                                                                        handleChange={this.handleOnchangeSelectBoxNew}
                                                                        name="villageName"
                                                                        options={this.state.vc_listArr}
                                                                        placeholderName={'Village'}
                                                                        dropdownIndicator={{ DropdownIndicator }}
                                                                    />
                                                            </Col>
                                                            </Row>
                                                        </Col>
                                                        
                                                    </Row>
                                                </div>
                                                )
                                                :
                                                ("")
                                            ) 
                                    )
                                )
                            }
                           
                           
                            
                            </>
                        )
                        :
                        ("")
                    }

                    {
                        this.state.photosShowBlock == true ?
                        (
                            <Row className='justify-content-center'>
                                    <Col sm={4} className='mt-2 mb-2'>
                                        <div className= "bg_white_color">
                                            <h6 className='f-12'>Please Check Photo To Get Names:</h6>
                                            <div class="d-flex">
                                                <div className={this.state.checkPhotoValue == "1" ? "border_thick tick_check_css" :""} onClick={() => this.checkPhotoClick("1")}>
                                                    {
                                                        this.state.typeOfLeaderValue == "election" ?
                                                        (<img src={require('../../images/kolli.jpeg')} className='width_height_img img-thumbnail' alt="..." />)
                                                        :
                                                        (<img src={require('../../images/achamnaidu.jpg')} className='width_height_img img-thumbnail' alt="..." />)
                                                    }
                                                    
                                                </div>
                                                <div className={this.state.checkPhotoValue == "2" ? "border_thick tick_check_css2" :""} onClick={() => this.checkPhotoClick("2")}>
                                                    {
                                                        this.state.typeOfLeaderValue == "election" ? 
                                                        (<img src={require('../../images/kolli1.jpeg')} className='width_height_img img-thumbnail' alt="..." />)
                                                        :
                                                        (<img src={require('../../images/Somireddy-Chandramohan-Reddy_Nominated.jpg')} className='width_height_img img-thumbnail' alt="..."  />)
                                                    }
                                                    
                                                </div>
                                                <div className={this.state.checkPhotoValue == "3" ? "border_thick tick_check_css3" :""} onClick={() => this.checkPhotoClick("3")}>
                                                    {
                                                         this.state.typeOfLeaderValue == "election" ? 
                                                         (<img src={require('../../images/kolli2.jpeg')} className='width_height_img img-thumbnail' alt="..." />)
                                                         :
                                                         (<img src={require('../../images/Yanamala-Ramakrishnudu_MLA.jpg')} className='width_height_img img-thumbnail' alt="..."  />)
                                                    }
                                                    
                                                </div>
                                            
                                            </div>
                                        
                                            
                                        </div>
                                    </Col>
                                </Row>
                        )
                        :
                        ("")
                    }

                    <div className='scroll_top'>
                        {
                            this.state.checkCorrectNameBlock == true ?
                            (
                            
                                    <Row className='justify-content-center'>
                                        <Col sm={4}>
                                        <div className='bg_white_color1 mt-3' data-aos="fade-up" data-aos-delay="100">
                                        <h6 className='f-14'>{this.state.onChangeECName} </h6>
                                            <Table striped bordered hover className="table_custom_css">
                                                <thead>
                                                    {
                                                        this.state.typeOfLeaderValue == "election" ? 
                                                        (
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Gender</th>
                                                                <th>MobileNo</th>
                                                                <th>Correct Name</th>
                                                            </tr>
                                                        )
                                                        :
                                                        (
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Designation</th>
                                                                <th>Correct Name</th>
                                                            </tr>
                                                        )
                                                    }
                                                    
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        {
                                                            this.state.typeOfLeaderValue == "election" ? 
                                                            (<>
                                                                <td>Seshadri Kolli</td>
                                                                <td>M</td>
                                                                <td>9885583999</td>
                                                                <td>
                                                                    <Form>
                                                                        <Form.Check
                                                                                inline
                                                                                label={""}
                                                                                name="checkCorrectNameVal"
                                                                                type="radio"
                                                                                id={`name1Id`}
                                                                                className="input_style mt-1 text-center radio_button_pos_center"
                                                                                onChange={(e)=>this.checkCorrectPersonIdentified(e,"1","Seshadri Kolli","correct")}
                                                                                value="1"
                                                                                checked={this.state.checkPersonTick1}
                                                                        ></Form.Check>
                                                                    </Form>

                                                                
                                                                
                                                                    
                                                                </td>
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <><td>ATCHENNAYUDU KINJARAPU</td>
                                                                <td>President</td>
                                                                <td>
                                                                    <Form>
                                                                        <Form.Check
                                                                                inline
                                                                                label={""}
                                                                                name="checkCorrectNameVal"
                                                                                type="radio"
                                                                                id={`name1Id`}
                                                                                className="input_style mt-1 text-center radio_button_pos_center"
                                                                                onChange={(e)=>this.checkCorrectPersonIdentified(e,"1","ATCHENNAYUDU KINJARAPU","correct")}
                                                                                value="1"
                                                                                checked={this.state.checkPersonTick1}
                                                                        ></Form.Check>
                                                                    </Form>

                                                                
                                                                
                                                                    
                                                                </td>
                                                                </>
                                                            )
                                                        }
                                                        
                                                      
                                                    </tr>
                                                    <tr>
                                                        
                                                        {
                                                            this.state.typeOfLeaderValue == "election" ? 
                                                            (<>
                                                                <td>Seshagirirao </td>
                                                                <td>M</td>
                                                                <td>9885583888</td>
                                                                <td>
                                                                <Form>
                                                                    <Form.Check
                                                                            inline
                                                                            label={""}
                                                                            name="checkCorrectNameVal"
                                                                            type="radio"
                                                                            id={`name2Id`}
                                                                            className="input_style mt-1 radio_button_pos_center"
                                                                            onChange={(e)=>this.checkCorrectPersonIdentified(e,"2","Seshagirirao","correct")}
                                                                            value="2"
                                                                            checked={this.state.checkPersonTick2}
                                                                    ></Form.Check>
                                                                </Form>
                                                                
                                                                
                                                            
                                                            </td>
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <><td>Somireddy Chandramohan Reddy</td>
                                                                <td>Politburo Member</td>
                                                                <td>
                                                                <Form>
                                                                    <Form.Check
                                                                            inline
                                                                            label={""}
                                                                            name="checkCorrectNameVal"
                                                                            type="radio"
                                                                            id={`name2Id`}
                                                                            className="input_style mt-1 radio_button_pos_center"
                                                                            onChange={(e)=>this.checkCorrectPersonIdentified(e,"2","Somireddy Chandramohan Reddy","correct")}
                                                                            value="2"
                                                                            checked={this.state.checkPersonTick2}
                                                                    ></Form.Check>
                                                                </Form>
                                                                
                                                                
                                                            
                                                            </td>
                                                                </>
                                                            )
                                                        }
                                                       
                                                    </tr>
                                                    <tr>
                                                        
                                                        {
                                                            this.state.typeOfLeaderValue == "election" ? 
                                                            (<>
                                                                <td>Venkat </td>
                                                                <td>M</td>
                                                                <td>9346170512</td>
                                                                <td>
                                                                    <Form>
                                                                        <Form.Check
                                                                                inline
                                                                                label={""}
                                                                                name="checkCorrectNameVal"
                                                                                type="radio"
                                                                                id={`name4Id`}
                                                                                className="input_style mt-1 radio_button_pos_center"
                                                                                onChange={(e)=>this.checkCorrectPersonIdentified(e,"3","Venkat","correct")}
                                                                                value="3"
                                                                                checked={this.state.checkPersonTick3}
                                                                        ></Form.Check>
                                                                    </Form>
                                                                    
                                                                
                                                                </td>
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <><td>Yanamala Ramakrishnudu</td>
                                                                <td>Politburo Member</td>
                                                                <td>
                                                                    <Form>
                                                                        <Form.Check
                                                                                inline
                                                                                label={""}
                                                                                name="checkCorrectNameVal"
                                                                                type="radio"
                                                                                id={`name4Id`}
                                                                                className="input_style mt-1 radio_button_pos_center"
                                                                                onChange={(e)=>this.checkCorrectPersonIdentified(e,"3","Yanamala Ramakrishnudu","correct")}
                                                                                value="3"
                                                                                checked={this.state.checkPersonTick3}
                                                                        ></Form.Check>
                                                                    </Form>
                                                                    
                                                                
                                                                </td>
                                                                </>
                                                            )
                                                        }
                                                        
                                                    </tr>
                                                    <tr>
                                                        
                                                        {
                                                            this.state.typeOfLeaderValue == "election" ? 
                                                            (<>
                                                                <td>Ramesh BABU </td>
                                                                <td>M</td>
                                                                <td>9346170512</td>
                                                                <td>
                                                                    <Form>
                                                                        <Form.Check
                                                                                inline
                                                                                label={""}
                                                                                name="checkCorrectNameVal"
                                                                                type="radio"
                                                                                id={`name3Id`}
                                                                                className="input_style mt-1 radio_button_pos_center"
                                                                                onChange={(e)=>this.checkCorrectPersonIdentified(e,"4","Ramesh BABU","wrong")}
                                                                                value="4"
                                                                                checked={this.state.checkPersonTick4}
                                                                        ></Form.Check>
                                                                    </Form>
                                                                
                                                                
                                                                </td>
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <><td>Chintakayala Ayyanna Patrudu</td>
                                                                <td>Politburo Member</td>
                                                                <td>
                                                                    <Form>
                                                                        <Form.Check
                                                                                inline
                                                                                label={""}
                                                                                name="checkCorrectNameVal"
                                                                                type="radio"
                                                                                id={`name3Id`}
                                                                                className="input_style mt-1 radio_button_pos_center"
                                                                                onChange={(e)=>this.checkCorrectPersonIdentified(e,"4","Chintakayala Ayyanna Patrudu","wrong")}
                                                                                value="4"
                                                                                checked={this.state.checkPersonTick4}
                                                                        ></Form.Check>
                                                                    </Form>
                                                                
                                                                
                                                                </td>
                                                                </>
                                                            )
                                                        }
                                                        
                                                    </tr>
                                                    
                                                    <tr>
                                                        
                                                        {
                                                            this.state.typeOfLeaderValue == "election" ? 
                                                            (<>
                                                                <td>KHASIM KHAN BAABU </td>
                                                                <td>M</td>
                                                                <td>9346170512</td>
                                                                <td>
                                                                    <Form>
                                                                        <Form.Check
                                                                                inline
                                                                                label={""}
                                                                                name="checkCorrectNameVal"
                                                                                type="radio"
                                                                                id={`name5Id`}
                                                                                className="input_style mt-1 radio_button_pos_center"
                                                                                onChange={(e)=>this.checkCorrectPersonIdentified(e,"5","KHASIM KHAN BAABU","wrong")}
                                                                                value="5"
                                                                                checked={this.state.checkPersonTick5}
                                                                        ></Form.Check>
                                                                    </Form>
                                                                
                                                                </td>
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <><td>Varla Ramaiah</td>
                                                                <td>Politburo Member</td>
                                                                <td>
                                                                <Form>
                                                                    <Form.Check
                                                                            inline
                                                                            label={""}
                                                                            name="checkCorrectNameVal"
                                                                            type="radio"
                                                                            id={`name5Id`}
                                                                            className="input_style mt-1 radio_button_pos_center"
                                                                            onChange={(e)=>this.checkCorrectPersonIdentified(e,"5","Varla Ramaiah","wrong")}
                                                                            value="5"
                                                                            checked={this.state.checkPersonTick5}
                                                                    ></Form.Check>
                                                                </Form>
                                                               
                                                            </td></>
                                                            )
                                                        }
                                                        
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                
                                
                            )
                            :
                            ("")
                        }
                    </div>

                    

                    
                    <Correct_WrongPersonModal 
                        show={this.state.checkCorrectPersonModal}
                        onHide={() => this.setState({ checkCorrectPersonModal: false })}
                        stateData={this.state}
                        closePersonModal={this.closePersonModal}
                        choosePersonIdentification={this.choosePersonIdentification}
                        showCorrectPersonInModalBlock={this.showCorrectPersonInModalBlock}
                    />
                    
                </Container>
            </section>
        )
    }
  }
  
  export default withRouter(LeaderSearchSection);
  