
import React ,{ Component } from 'react';
import {Container, Row, Col,Form,Button,Image,Card,Table,InputGroup} from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif'; 
import './LeaderSearch.css';
import moment from "moment";

class LeaderNotMatchedSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dateSelected:props?.location?.params?.dateSelected == undefined ? "" : props?.location?.params?.dateSelected,
            selectedType:props?.location?.params?.selectedType == undefined ? "" : props?.location?.params?.selectedType,
            whichISelected:"",
            correctOne:"",
            markDone:"",
            markDoneLoading:"hide",
            selectedMarkDoneId:"",
            notMatchedArr:[{"id":1,name:"ATCHENNAYUDU KINJARAPU",designation:"President",chekedCorrect:false,markDone:false,whichISelected:false,photos:[{id:1,photo:"achamnaidu.jpg",selected:"yes",correctOne:"no"},{id:2,photo:"td_janardhan_rao.jpg",selected:"no",correctOne:"yes"},{id:3,photo:"Somireddy-Chandramohan-Reddy_Nominated.jpg",selected:"no",correctOne:"no"}]},
            {"id":2,name:"ATCHENNAYUDU KINJARAPU",designation:"President",chekedCorrect:false,markDone:false,whichISelected:false,photos:[{id:1,photo:"achamnaidu.jpg",selected:"no",correctOne:"yes"},{id:2,photo:"td_janardhan_rao.jpg",selected:"yes",correctOne:"no"},{id:3,photo:"Somireddy-Chandramohan-Reddy_Nominated.jpg",selected:"no",correctOne:"no"}]},
            {"id":3,name:"ATCHENNAYUDU KINJARAPU",designation:"President",chekedCorrect:false,markDone:false,whichISelected:false,photos:[{id:1,photo:"achamnaidu.jpg",selected:"yes",correctOne:"no"},{id:2,photo:"td_janardhan_rao.jpg",selected:"no",correctOne:"yes"},{id:3,photo:"Somireddy-Chandramohan-Reddy_Nominated.jpg",selected:"no",correctOne:"no"}]}]
        }
    }
  
    componentDidMount() {
        if(this.state.selectedType == ""){
            this.props.history.push("/leader_dashboard");
        }
    }

   

    goToSearchPage = () => {
        this.props.history.push({
            pathname: `/leader_search`,
        }); 
    }

    goToDashboardPage = () => {
        this.props.history.push({
            pathname: `/leader_dashboard`,
        }); 
    }
    notMatchedDetails = () => {
        this.props.history.push({
            pathname: `/leader_notMatched_details`,
        }); 
    }
    
    showMeWhichISelected = (data) => {
        const getData = this.state.notMatchedArr.map((item) => {
            if (item.id == data.id) {
                return { ...item, whichISelected: true,chekedCorrect:false }
            }
            return { ...item }
        });

        this.setState({notMatchedArr:getData})
    }
    showMeCorrectOne = (data) => {
        const getData = this.state.notMatchedArr.map((item) => {
            if (item.id == data.id) {
                return { ...item, chekedCorrect: true,whichISelected:false }
            }
            return { ...item }
        });

        this.setState({notMatchedArr:getData})
    }
    markAsDone = (data) => {
        this.setState({selectedMarkDoneId:data.id});
        this.setState({markDoneLoading:"show"});
        
        
        setTimeout(() => 
            this.updatedRecords(data)
        , 1500);
        
    }

    updatedRecords = (data) => {
        const getData = this.state.notMatchedArr.map((item) => {
            if (item.id == data.id) {
                return { ...item, markDone: true }
            }
            return { ...item }
        });
        this.setState({markDoneLoading:"hide"})
        this.setState({notMatchedArr:getData})
    }

    render() {
        console.log("selectedMarkDoneId",this.state.selectedMarkDoneId)
        return (
            <>
            <section className='leader_bg_color1'>
                <Container  className=''>
                    <Row>
                        <Col>
                            <img src={require('../../images/leadership-icon.png')} alt="leader-ship" className='w_80 center-auto mt-2'></img>
                            <h3 className='text-center color_white fw-bold font-style'>LEADERS SEARCH</h3>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col sm={4}>
                            <Button variant="outline-light" className='f-12 float-end fw-bold  mb-2 ms-2' onClick={() => this.goToSearchPage()}> HOME <i class="fa-solid fa-house"></i></Button>
                            <Button variant="outline-light" className='f-12 float-end fw-bold  mb-2' onClick={() => this.goToDashboardPage()}> DASHBOARD <i class="fa-solid fa-gauge"></i></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <Row className='justify-content-center'>
                        <Col sm={4}>
                            {
                                this.state.selectedType == "NotMatched" ?
                                (<h6 className='mt-3 fw-bold text-center'>Not Matched Photos - {this.state.dateSelected} : </h6>)
                                :
                                (<h6 className='mt-3 fw-bold text-center'>Matched Photos - {this.state.dateSelected} : </h6>)
                            }
                            
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        {
                            this.state.notMatchedArr.filter(a => a.markDone == false).map((item,index) => {
                                return(
                                    <Col sm={4}>
                                        <Card>
                                            <Card.Body>
                                                <span className='f-12 bg_round'>{item.id}</span>
                                                <h6 className='f-12'>Leader Name : <b>{item.name}</b></h6>
                                                <h6 className='f-12 mt-2'>Designation : <b>{item.designation}</b></h6>
                                                <div class="d-flex mt-2">
                                                    
                                                   {
                                                    this.state.selectedType == "Matched" ?
                                                    (
                                                        
                                                        item.photos.map((item2,index2) => {
                                                            return(
                                                                item2.correctOne == "yes" ?
                                                                (
                                                                    <div className='tick_green_css'><img src={require(`../../images/${item2.photo}`)} className='width_height_img img-thumbnail' alt="..." /></div>
                                                                )
                                                                :
                                                                (
                                                                    <div><img src={require(`../../images/${item2.photo}`)} className='width_height_img img-thumbnail' alt="..." /></div>
                                                                )
                                                            )

                                                        })
                                                       
                                                        
                                                    )
                                                    :
                                                    (
                                                        item.photos.map((item2,index2) => {
                                                            return(
                                                                
                                                                    item.chekedCorrect == true ?
                                                                    (
                                                                        item2.correctOne == "yes" ?
                                                                        (
                                                                            <div className='tick_green_css'><img src={require(`../../images/${item2.photo}`)} className='width_height_img img-thumbnail' alt="..." /></div>
                                                                        )
                                                                        :
                                                                        (
                                                                            <div className={(`tick_red_css${index2}`)}><img src={require(`../../images/${item2.photo}`)} className='width_height_img img-thumbnail' alt="..." /></div>
                                                                        )
                                                                        
                                                                    )
                                                                    :
                                                                    (
                                                                        item.whichISelected == true ?
                                                                        (
                                                                            item2.selected == "yes" ?
                                                                            (
                                                                                <div className='tick_yellow_css'><img src={require(`../../images/${item2.photo}`)} className='width_height_img img-thumbnail' alt="..." /></div>
                                                                            )
                                                                            :
                                                                            (
                                                                                <div ><img src={require(`../../images/${item2.photo}`)} className='width_height_img img-thumbnail' alt="..." /></div>
                                                                            )
                                                                        )
                                                                        :
                                                                        (
                                                                            <div><img src={require(`../../images/${item2.photo}`)} className='width_height_img img-thumbnail' alt="..." /></div>
                                                                        )
    
                                                                    )
                                                                
                                                                
                                                                
                                                            )
                                                        }
                                                    )

                                                   
                                                        
                                                    )
                                                   }
                                                    
                                                </div>
                                                {
                                                    this.state.selectedType != "Matched" ?
                                                    (
                                                        <Row className='mt-3'>
                                                        <Col>
                                                            <Button variant="outline-warning" className='f-10  fw-bold  pad_5 ms-2' onClick={() => this.showMeWhichISelected(item)}> Show Me Which I Selected</Button>
                                                            <Button variant="outline-success" className='f-10  fw-bold pad_5 ms-2' onClick={() => this.showMeCorrectOne(item)}> Show Me Correct Once </Button>
                                                        </Col>
                                                    </Row>
                                                    )
                                                    :
                                                    ("")
                                                }
                                               
                                                <Row className='mt-2'>
                                                    <Col>
                                                        <Button variant="outline-dark" className='f-10  fw-bold pad_5 center-auto' onClick={() => this.markAsDone(item)}> Mark As Done
                                                        {
                                                            this.state.markDoneLoading == "show" && item.id == this.state.selectedMarkDoneId ?
                                                            (<Image src={loader} alt="loader" className='get_otp_loading_symbol_cls22'></Image>)
                                                            :
                                                            ("")
                                                        }
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                       
                        
                    </Row>
                </Container>
            </section>
            </>
        )
    }
  }
  
  export default withRouter(LeaderNotMatchedSection);
  