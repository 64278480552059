
import React ,{ Component } from 'react';
import {Container, Row, Col,Form,Card} from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import "./Footer.css";

class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
    }
  
    componentDidMount() {

       
    }


    render() {
        
        return (
            <>
                <footer className='bg_red_footer'>
                     <Container>
                        <Row>
                            <Col sm={3}>
                                <img src= "https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/f-logo.png" alt="f-logo" className='center-auto'></img>
                            </Col>
                            <Col sm={9}>
                                <Row>
                                    <Col>
                                        <h6 className='color_yellow fw-bold'>మన పార్టీ</h6>
                                        <ul class="list-inline elementor-icon-list-items mt-4">
                                                <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/our-philosophy/">

                                                                <span class="elementor-icon-list-text">మన సిద్ధాంతం</span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/jai-hind-and-jai-telugu-desam/">

                                                                <span class="elementor-icon-list-text">జై హింద్ - జై తెలుగుదేశం</span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/tdp-history/">

                                                                <span class="elementor-icon-list-text">మన చరిత్ర</span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/tdp-impact-on-the-nation/">

                                                                <span class="elementor-icon-list-text">జాతీయ రాజకీయాలపై తెలుగుదేశం ప్రభావం</span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/party-organization-structure/">

                                                                <span class="elementor-icon-list-text">కార్య నిర్వాహక వర్గం</span>
                                                                </a>
                                                        </li>
                                            </ul>
                                    </Col>
                                    <Col>
                                    <h6 className='color_yellow fw-bold'>మన నాయకత్వం</h6>
                                        <ul class="list-inline elementor-icon-list-items mt-4">
                                                <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/life-and-history-of-our-founder/">

                                                                <span class="elementor-icon-list-text">వ్యవస్థాపకుని జీవిత చరిత్ర</span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/life-and-history-of-our-leader-ncbn/">

                                                                <span class="elementor-icon-list-text">పార్టీ అధినాయకుని జీవిత చరిత్ర</span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/ntr-centenary-celebrations/">

                                                                <span class="elementor-icon-list-text">NTR శత జయంతి ఉత్సవాలు</span>
                                                                </a>
                                                        </li>
                                            </ul>
                                    </Col>
                                    <Col>
                                        <h6 className='color_yellow fw-bold'>మన విజయాలు</h6>
                                        <ul class="list-inline elementor-icon-list-items mt-4">
                                                    <li class="elementor-icon-list-item">
                                                        <a href="https://www.telugudesam.org/role-in-building-the-nation/">

                                                        <span class="elementor-icon-list-text">భారత దేశం</span>
                                                        </a>
                                                </li>
                                            <li class="elementor-icon-list-item">
                                                        <a href="https://www.telugudesam.org/role-in-building-the-nation-andhra-pradesh/">

                                                        <span class="elementor-icon-list-text">ఆంధ్ర ప్రదేశ్</span>
                                                        </a>
                                                </li>
                                            <li class="elementor-icon-list-item">
                                                        <a href="https://www.telugudesam.org/role-in-building-the-nation-telangana/">

                                                        <span class="elementor-icon-list-text">తెలంగాణ</span>
                                                        </a>
                                                </li>
                                            <li class="elementor-icon-list-item">
                                                        <a href="https://www.telugudesam.org/40-years-of-success/">

                                                        <span class="elementor-icon-list-text">40 సంవత్సరాల విజయాలు</span>
                                                        </a>
                                                </li>
                                            </ul>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mt-4'>
                                        <h6 className='color_yellow fw-bold'>డౌన్లోడ్స్</h6>
                                        <ul class="list-inline elementor-icon-list-items mt-4">
                                                <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/manifesto/">

                                                                <span class="elementor-icon-list-text">మ్యానిఫెస్టో</span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/2022-mahanadu-resolutions/">

                                                                <span class="elementor-icon-list-text">మహానాడు 2022 తీర్మానాలు</span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="http://www.photowithmyleader.com/">

                                                                <span class="elementor-icon-list-text">నాయకుడితో నా చిత్రం </span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/party-logos/">

                                                                <span class="elementor-icon-list-text">లోగోలు</span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/pictures-of-our-leaders/">

                                                                <span class="elementor-icon-list-text">నాయకుల చిత్రాలు </span>
                                                                </a>
                                                        </li>
                                            </ul>
                                    </Col>
                                    <Col className='mt-4'>
                                    <h6 className='color_yellow fw-bold'>ప్రచార సమాచారం</h6>
                                        <ul class="list-inline elementor-icon-list-items mt-4">
                                                <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/party-banners-and-booklets/">

                                                                <span class="elementor-icon-list-text">బ్యానర్స్ / బుక్ లెట్స్ </span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/posters-and-brochures/">

                                                                <span class="elementor-icon-list-text">పోస్టర్స్ / కరపత్రాలు </span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/songs/">

                                                                <span class="elementor-icon-list-text">ప్రచార గీతాలు </span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/election-information-and-suggestions/">

                                                                <span class="elementor-icon-list-text">ఎన్నికల సమాచారం / సూచనలు </span>
                                                                </a>
                                                        </li>
                                            </ul>
                                    </Col>
                                    <Col className='mt-4'>
                                        <h6 className='color_yellow fw-bold'>మీడియా వనరులు</h6>
                                            <ul class="list-inline elementor-icon-list-items mt-4">
                                                <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/press-releases/">

                                                                <span class="elementor-icon-list-text">పత్రికా ప్రకటనలు</span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/speeches/">

                                                                <span class="elementor-icon-list-text">ప్రసంగాలు</span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/programs/">

                                                                <span class="elementor-icon-list-text">కార్యక్రమాలు</span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/interviews-articles/">

                                                                <span class="elementor-icon-list-text">ముఖాముఖి &amp; కథనాలు</span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/photo-gallery/">

                                                                <span class="elementor-icon-list-text">ఫోటో గ్యాలరీ</span>
                                                                </a>
                                                        </li>
                                                    <li class="elementor-icon-list-item">
                                                                <a href="https://www.telugudesam.org/video-gallery/">

                                                                <span class="elementor-icon-list-text">వీడియో గ్యాలరీ</span>
                                                                </a>
                                                        </li>
                                            </ul>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                     </Container> 
                      
                </footer>
                <section className='bg_yellow-footer '>
                    <Container>
                        <Row>
                            <Col>
                                <h6 className='fw-bold mt-3 color_red'>© 2023 తెలుగు దేశం పార్టీ </h6> 
                            </Col>
                        </Row>
                    </Container>
                </section>
                
                
            </>
        )
    }
  }
  
  export default withRouter(Footer);
  