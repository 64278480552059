
import React ,{ Component } from 'react';
import {Container, Row, Col,Navbar,Card,Nav,Form,Button,Image} from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import '../../components/NavBar.css'
import loader from '../../images/loader.gif'; 

const queryParams = new URLSearchParams(window.location.search);
let enrollId = queryParams.get('i');
console.log("student_type",enrollId);

export async function getAPICallData(data,url) {
    
    const response  =    fetch('https://www.mypartydashboard.com/BSA/WebService/'+url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'user_data':''
        },
     body: data
  })

   return response;
}

class LoginPageWithScanUrl extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mobileNo:"",
            validateMobileNo:"",

            smsSentMessage:"",
            otpVerifiedMessageStatus:"",
            otpLoadingSymbol:"hide",
            getOTPLoadingSymbol:"hide",
            OTPSentStatus:"",
            resendOTPLoadingSymbol:"hide",
            
            minutes:1,
            seconds:0,
        }
    }
  
    componentDidMount() {
        this.getMobileNumber();
       
    }

    getMobileNumber = () => {
        fetch(`https://projectkv.com/bot_api/v1/getEnrollmentByShortUrl/${enrollId}`, {
            method: "GET", 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "test_chat_id" :"5129193582",
            },
          })
          .then((res) => res.json())
          .then((json) => {
              if(json !=null && json.mobileNo != ""){
                this.setState({ mobileNo:json.mobileNo});
              }else{
                this.setState({ mobileNo:""});
              }
            
          });
    }

    handleOnchangeInputBox = (e) => {
        this.setState({validateInterests:"" });
        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;
        const re = /^[0-9\b]+$/;
        

       
        if(name == "volunteerOTPNO"){
            if(value.length >= 6){
                this.validateMobileOtp(value);
            }
            
        }

        

        this.setState({ [name]: value });
        
      }

    resendOTPTimer = () => {
        this.setState({minutes:1});
        this.setState({seconds:0});

        this.OTPTimerFunction();
      };

    OTPTimerFunction = () => {
        const interval = setInterval(() => {
            if (this.state.seconds > 0) {
              this.setState({seconds:this.state.seconds - 1});
            }
      
            if (this.state.seconds === 0) {
              if (this.state.minutes === 0) {
                clearInterval(interval);
              } else {
                this.setState({seconds:59});
                this.setState({minutes:this.state.minutes - 1});

              }
            }
          }, 1000);
      
          return () => {
            clearInterval(interval);
          };
    }

    resendOTPNew = () => {
        this.setState({validateVolunteerMobile:"" });
        if(this.state.mobileNo == ""){
            this.setState({validateMobileNo:"Please Enter Mobile No" });
            return;
        }
        var expr = /^(0|91)?[6-9][0-9]{9}$/;
        if(!expr.test(this.state.mobileNo)){
            this.setState({validateMobileNo:"Please Enter Valid Mobile No" });
            return;
          }

        

        if(this.state.mobileNo.length < 10){
            this.setState({validateMobileNo:"Please Enter Valid Mobile No" });
            return;
        }


        this.setState({resendOTPLoadingSymbol:"show"});
       
        const obj =  
            JSON.stringify({ 
            "chatId" :'943827154',
            "mobileNo" : this.state.mobileNo,
            
            }); 

            getAPICallData(obj,"sendOtpToMobileNumber")
            .then((res) => res.json())
            .then((data) => {
              this.setState({resendOTPLoadingSymbol:"hide"});
              if(data !=null && data.status == "Success"){
                this.resendOTPTimer();
              }
            });
    }
  
    getMobileNoOTP = () => {
        this.setState({validateMobileNo:"" });
        var expr = /^(0|91)?[6-9][0-9]{9}$/;

        if(this.state.mobileNo == ""){
            this.setState({validateMobileNo:"Please Enter Mobile No" });
            return;
        }

        if(!expr.test(this.state.mobileNo)){
            this.setState({validateMobileNo:"Please Enter Valid Mobile No" });
            return;
          }

        if(this.state.mobileNo.length < 10){
            this.setState({validateMobileNo:"Please Enter Valid Mobile No" });
            return;
        }


        this.setState({smsSentMessage:"",getOTPLoadingSymbol:"show",OTPSentStatus:""});
        
        const obj =  
            JSON.stringify({ 
            "chatId" :'943827154',
            "mobileNo" : this.state.mobileNo,
            
            }); 

            getAPICallData(obj,"sendOtpToMobileNumber")
            .then((res) => res.json())
            .then((data) => {
              this.setState({getOTPLoadingSymbol:"hide"});
              if(data !=null && data.status == "Success"){
                this.setState({smsSentMessage:"OTP Sent Your Mobile No",OTPSentStatus:data.status});
                this.OTPTimerFunction();
              }else{
                this.setState({smsSentMessage:"Some Thing Went Wrong,Please Try Again...",OTPSentStatus:"Fail"});
              }
            });
    }

    


    validateMobileOtp = (otp) => {
        this.setState({otpVerifiedMessageStatus:"",otpLoadingSymbol:"show"});
        const obj =  
            JSON.stringify({ 
            "chatId" :'943827154',
            "mobileNo" : this.state.mobileNo,
            "otp" : otp
            

            }); 

            getAPICallData(obj,"validateMobileOtp")
            .then((res) => res.json())
            .then((data) => {
               this.setState({otpLoadingSymbol:"hide"});
               if(data !=null && data.status == "Success"){
                this.setState({otpVerifiedMessageStatus:"Verified"});
                this.props.history.push({
                    pathname: `/p/?i=${enrollId}`
                    
                }); 
              }else{
                this.setState({otpVerifiedMessageStatus:"NotVerified"});
              }
            });
    }

    render() {
        return (
            <section className='login_page_bg'>
                    <Container>
                    <Row>
                            <Col sm={6}>
                                <img src="https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/bharosa_img.png" alt="Bharosa_img" className='w_res1_100 center-auto mt-4'></img>
                            </Col>
                            <Col sm={6}>
                                <Row className='justify-content-center'>
                                    <Col sm={9}>
                                        <div className='image_position1'>
                                            <Card className="card_fp_shadow">
                                                <Card.Body>
                                                    <h6 className='text-center'>SIGN IN</h6>
                                                    <Form className=''>
                                                        <Form.Group className="" controlId="formMobileId">
                                                            <Form.Label className='f-14 fw-bold'>Mobile No<span className='color_red f-14 ms-1'>*</span>  <span className='color_red f-9'>{this.state.validateMobileNo}</span></Form.Label>
                                                            <Form.Control type="text" name="mobileNo" value={this.state.mobileNo} maxLength={10}  className='form-control-md'  placeholder="Enter Mobile No" onChange={(e) => this.handleOnchangeInputBox(e)} />
                                                        </Form.Group>
                                                        
                                                        {
                                                            this.state.OTPSentStatus == "Fail" || this.state.OTPSentStatus == "" ?
                                                            (
                                                                <>
                                                                <span className='f-12 color_red'>{this.state.smsSentMessage}</span>
                                                                <Button variant="warning" className='f-12 float-end btn-sm mt-2 fw-bold' onClick={() => this.getMobileNoOTP()}>GET OTP
                                                                {
                                                                    this.state.getOTPLoadingSymbol == "show" ?
                                                                    (<Image src={loader} alt="loader" className='ms-2 get_otp_loading_symbol_cls'></Image>)
                                                                    :
                                                                    ("")
                                                                } 
                                                                </Button> 
                                                                </>
                                                            )
                                                            :
                                                            ("")
                                                        }
                                                    </Form>
                                                    
                                                            <Form>
                                                                <Form.Group className="mt-4" controlId="formOTPId">
                                                                    <Form.Label className='f-12 fw-bold'>OTP No<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.state.validateVolunteerOTP}</span></Form.Label>
                                                                    <Form.Control type="text" name="volunteerOTPNO" maxLength={6} className='form-control-md' value={this.state.volunteerOTPNO}  placeholder="Enter OTP No" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                                    <Row>
                                                                        <Col>
                                                                            {
                                                                                this.state.OTPSentStatus == "Success"?
                                                                                (
                                                                                    <>
                                                                                        <h6 className='mt-2 f-12 color_green line_height_20'>{this.state.smsSentMessage} : <b >{this.state.volunteerMobile}</b></h6>
                                                                                        
                                                                                        {
                                                                                            this.state.otpVerifiedMessageStatus != "Verified" || this.state.otpVerifiedMessageStatus == "" ?
                                                                                            (
                                                                                                <div className="countdown-text">
                                                                                                    {this.state.seconds > 0 || this.state.minutes > 0 ? (
                                                                                                    <h6 className='mt-2 f-14'>
                                                                                                        Time Remaining: {this.state.minutes < 10 ? `0${this.state.minutes}` : this.state.minutes}:
                                                                                                        {this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds}
                                                                                                    </h6>
                                                                                                    ) : (
                                                                                                    <h6 className='mt-2 f-12'>Didn't recieve code?</h6>
                                                                                                    )}
                                                                                                    {
                                                                                                        this.state.resendOTPLoadingSymbol == "show" ?
                                                                                                        (<Image src={loader} alt="loader" className='ms-2 get_otp_loading_symbol_cls'></Image>)
                                                                                                        :
                                                                                                        ("")
                                                                                                    } 
                                                                                                    <Button variant="dark" className='f-12 float-right mt-2' 
                                                                                                    disabled={this.state.seconds > 0 || this.state.minutes > 0}
                                                                                                    style={{
                                                                                                        color: this.state.seconds > 0 || this.state.minutes > 0 ? "#DFE3E8" : "#fff",
                                                                                                    }} onClick={this.resendOTPNew}  >Resend OTP
                                                                                                    {
                                                                                                            this.state.getOTPLoadingSymbol == "show" ?
                                                                                                            (<Image src={loader} alt="loader" className='ms-2 get_otp_loading_symbol_cls'></Image>)
                                                                                                            :
                                                                                                            ("")
                                                                                                        } 
                                                                                                    </Button>
                                                                                                    
                                                                                                </div>
                                                                                            )
                                                                                            :
                                                                                            ("")
                                                                                        }
                                                                                        
                                                                                        
                                                                                    </>
                                                                                )
                                                                                :
                                                                                ("")
                                                                            }
                                                                        </Col>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col>
                                                                            {
                                                                                    this.state.otpLoadingSymbol == "show" ?
                                                                                    (<Image src={loader} alt="loader" className='loader_cls_otp ms-2'></Image>)
                                                                                    :
                                                                                    (
                                                                                        this.state.otpVerifiedMessageStatus == "Verified" ?
                                                                                        (

                                                                                            <h6 class="color_green fw-bold f-14  text-center mt-3"><i class="fa-solid fa-check mr-2"> </i>OTP verified successfully</h6>
                                                                                            
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            this.state.otpVerifiedMessageStatus == "NotVerified" ?
                                                                                            (<h6 class="color_red fw-bold f-14 text-center mt-3"><i class="fa-solid fa-check mr-2"> </i>Not Verified,Please Enter Valid OTP</h6>)
                                                                                            :
                                                                                            ("")
                                                                                            
                                                                                        
                                                                                        )
                                                                                )
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group> 
                                                            </Form>  
                                                        
                                                    
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                </Row>
                            
                            </Col>
                        </Row>
                        
                    </Container>   

            </section>
        )
    }
  }
  
  export default withRouter(LoginPageWithScanUrl);
  