import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image,Card } from "react-bootstrap";
import loader from '../../images/loader.gif';


class Correct_WrongPersonModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            images:[{id:1,photo:"achamnaidu.jpg",name:"ATCHENNAYUDU KINJARAPU",designation:"President"},{id:2,photo:"Somireddy-Chandramohan-Reddy_Nominated.jpg",name:"Somireddy Chandramohan Reddy",designation:"Politburo Member"},
                    {id:3,photo:"Yanamala-Ramakrishnudu_MLA.jpg",name:"Yanamala Ramakrishnudu",designation:"Politburo Member"},
                    {id:4,photo:"ayyana1-1.jpg",name:"Chintakayala Ayyanna Patrudu",designation:"Politburo Member"},
                    {id:5,photo:"varla_ramaiah.jpg",name:"Varla Ramaiah",designation:"Politburo Member"}],
            imagesForEC:[{id:1,photo:"kolli.jpeg",name:"Seshadri Kolli",mobile:"9346170512",gender:"M"},
                        {id:2,photo:"kolli1.jpeg",name:"Seshagirirao",mobile:"9346170512",gender:"M"}
                        ,{id:3,photo:"kolli1.jpeg",name:"Venkat",mobile:"9346170512",gender:"M"}
                        ,{id:4,photo:"kolli2.jpeg",name:"Venkat",mobile:"9346170512",gender:"M"}
                        ,{id:5,photo:"kolli2.jpeg",name:"Venkat",mobile:"9346170512",gender:"M"}],
            
        }
      
    }

    
    render() {
        let photosArr=[];
        if(this.props.stateData.typeOfLeaderValue == "election" ){
            photosArr = this.state.imagesForEC
        }else{
            photosArr = this.state.images
        }
        return (
            <Modal {...this.props} 
            size="sm"  centered aria-labelledby="example-modal-sizes-title-lg" className="z_index_modal">
                <Modal.Body className="">
                      <Row>
                         <Col>
                            {
                                this.props.stateData.checkPhotoValue == this.props.stateData.checkPersonValue && this.props.stateData.checkSelectedMatchedType == "correct" ?
                                (<h6 className='mt-2 f-12 f-12'>Your Selected Image and Name&nbsp;<span className='color_green fw-bold'>MATCHED</span>
                                    <h6 className='fw-bold mt-2 f-16 ln-20'> for {this.props.stateData.checkSelectedPersonName}&nbsp;:&nbsp;</h6>
                                </h6>)
                                :
                                (<h6 className='mt-2 f-12 f-12'>Your Selected Image and Name&nbsp;<span className='color_red fw-bold'>NOT&nbsp;MATCHED</span>
                                    <h6 className='fw-bold mt-2 f-16 ln-20'> for {this.props.stateData.checkSelectedPersonName}&nbsp;:&nbsp;</h6>
                                </h6>)
                            }
                            
                            {
                                this.props.stateData.checkPhotoValue == this.props.stateData.checkPersonValue && this.props.stateData.checkSelectedMatchedType == "correct" ?
                                (
                                    
                                    photosArr.filter(img => img.id == this.props.stateData.checkPersonValue).map((item2,index2) => {
                                        return(
                                            <Row className='justify-content-center mt-3'>
                                                <Col sm={4}>
                                                    <div className='tick_green_modal_css'><img src={require(`../../images/${item2.photo}`)} className='width_height_img img-thumbnail border_thick center-auto' alt="..." /></div>
                                                </Col>
                                            </Row>
                                            
                                        )

                                    })
                                    
                                    
                                )
                                :
                                (
                                    
                                    photosArr.filter(img => img.id == this.props.stateData.checkPersonValue).map((item2,index2) => {
                                        return(
                                            <>
                                            {/*<Row className='justify-content-center mt-3'>
                                                <Col sm={4}>
                                                    <div className='tick_red_modal_css'><img src={require(`../../images/${item2.photo}`)} className='width_height_img img-thumbnail border_red_thick center-auto' alt="..." /></div>
                                                </Col>
                                        </Row>*/}

                                            <Row className='justify-content-center text-center'>
                                                <Col sm={4}>
                                                    <Button variant="outline-warning" className='f-10  fw-bold  pad_5  mt-3' onClick={() => this.props.closePersonModal()}> Select Correct Name Now</Button>
                                                    <Button variant="outline-success" className='f-10  fw-bold pad_5 mt-3 ms-2' onClick={() => this.props.showCorrectPersonInModalBlock()}>Show Correct Name</Button> 
                                                </Col>
                                            </Row>

                                            {
                                                this.props.stateData.showCorrectPersonInModalBlock == true ?
                                                (
                                                    <Row className='justify-content-center mt-2'>
                                                        {
                                                           photosArr.filter(img => img.id == this.props.stateData.checkPhotoValue).map((item,index2) => {
                                                                return(
                                                                    <Col sm={4}>
                                                                        <Card>
                                                                            <Card.Body>
                                                                                <h6 className='f-12'>Name : <b>{item.name}</b></h6>
                                                                                <h6 className='f-12 mt-2'>Designation : <b>{item.designation}</b></h6>
                                                                                    <Row className='justify-content-center mt-3'>
                                                                                        <Col sm={4}>
                                                                                            <div className='tick_green_modal_correct_css'><img src={require(`../../images/${item.photo}`)} className='width_height_img img-thumbnail border_thick center-auto' alt="..." /></div>
                                                                                        </Col>
                                                                                    </Row>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </Col>
                                                                )
                                                            })
                                                        }
                                                    
                                                        
                                                    </Row>
                                                )
                                                :
                                                ("")
                                            }
                                            
                                            </>
                                        )

                                    })
                                
                                    
                                )
                                }
                            
                            <Button variant="dark" className='f-12 float-end mt-3 btn-sm' onClick={() => this.props.closePersonModal()}>Close</Button> 
                         </Col>
                      </Row>
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default Correct_WrongPersonModal;
  

